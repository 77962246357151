import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Apollo, gql } from 'apollo-angular';
import { selectInstances, updateDocumentState } from 'editor';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { updateMetadata, updateTemplate } from '../store/export.actions';

import { JSONEditor } from '@json-editor/json-editor';
import { selectMetadata } from '../store/export.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'lib-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent {

  templateSlug$ = this.activatedRoute.params.pipe(map((p: any) => p.template), startWith(this.activatedRoute.snapshot.params?.value?.template));
  activeEditor$ = this.store.select(selectInstances).pipe(filter(instances => instances.length > 0), map(instances => instances[0]));
  activeTemplate$ = this.templateSlug$.pipe(
    startWith(this.activatedRoute.snapshot.params.template),
    debounceTime(100),
    switchMap((template: string) => this.loadTemplate(template)),
    shareReplay(1)
  );

  templates$ = this.loadTemplates();
  metaDataEditorDirty$ = new BehaviorSubject(false);

  @ViewChild('metaData') metaDataEl;
  metaDataEditor: JSONEditor;
  
  loading$ = new BehaviorSubject(false);

  data$ = combineLatest([
    this.activeEditor$,
    this.activeTemplate$,
    this.templates$
  ]).pipe(map(([editor, template, templates]) => ({ editor, template, templates })));

  constructor(private store: Store, private apollo: Apollo, private activatedRoute: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }

  async exportWithTemplate(template: string, key?: string, format = 'pagedjs'): Promise<void> {
    window.open(`/export/${format}/${template}/${key}`, '_blank');
  }

  async exportSnapshot(key: string): Promise<void> {
    window.open(`/export/snapshot/${key}.zip`, '_blank');
  }

  async openLens(template: string, key?: string, format = 'xml') {
    const url = `/export/lens/?url=/export/${format}/${template}/${key}`;
    window.open(url, '_blank');
  }

  compareTemplate(template, slug: string) {
    return template === slug;
  }

  canEdit(key?: string) {
    return key?.includes('.json');
  }

  /**
   * Select a template (and change the url)
   * @param template the template slug
   */
  async navigateToTemplate(template: string) {
    this.activatedRoute.snapshot.params.template ?
      this.router.navigate(['../' + template], { relativeTo: this.activatedRoute }) :
      this.router.navigate([template], { relativeTo: this.activatedRoute });
  }

  public resetMetaData() {
    this.store.dispatch(updateMetadata({ metaData: null }));
    this.store.dispatch(updateDocumentState({ dirty: true }));
  }

  private loadMetaDataEditor(schema: any, values?: any) {
    if (schema && this.metaDataEl) {
      this.metaDataEditor = new JSONEditor(this.metaDataEl?.nativeElement, {
        collapsed: false,
        disable_collapse: true,
        disable_edit_json: true,
        disable_properties: true,
        remove_empty_properties: true,
        schema: {
          ...schema
        },
        theme: 'html'
      });
      this.metaDataEditor.on('ready', () => {
        console.log('meta data editor ready', schema);
        this.metaDataEditorDirty$.next(false);
        // Now the api methods will be available
        if (values) {
          this.metaDataEditor.setValue({
            ...this.metaDataEditor.getValue(),
            ...values
          });
        } else {
          values = this.metaDataEditor.getValue();
        }

        this.metaDataEditor.validate();
      });

      this.metaDataEditor.on('change', () => {
        const value = this.metaDataEditor?.getValue();
        if (JSON.stringify(value) != JSON.stringify(values)) {
          this.metaDataEditorDirty$.next(true);
        } else {
          return;
        }

        console.log('values', value);

        if (value) {
          this.store.dispatch(updateMetadata({ metaData: value }));
          // TODO: move this out of the editor itself
          this.store.dispatch(updateDocumentState({ dirty: true }));
        }
      });
    }
  }

  async loadTemplates() {
    const templateResult: any = await this.apollo.query({
      query: gql`query GetTemplates {
        templates
      }`,
      variables: {}
    }).toPromise();

    return templateResult?.data?.templates;
  }

  async loadTemplate(template?: string) {

    this.closeMetaData();

    if (!template) { return null; }

    this.loading$.next(true);
    this.snackBar.open('Loading template ...');

    const documentResult: any = await this.apollo.query({
      query: gql`query GetTemplate($template: String!, $projectId: String) {
        template(templateId: $template, projectId: $projectId) {
          slug
          title
          description
          readme
          type
          metaData
          runners
        }
      }`,
      variables: {
        template
      }
    }).toPromise();

    this.loading$.next(false);
    this.snackBar.open('Loaded ' + documentResult.data?.template?.title, undefined, { duration: 2000 });
    console.log('loaded template', documentResult.data.template);

    this.store.dispatch(updateTemplate({ template: documentResult.data.template }));

    return documentResult?.data?.template;
  }

  async toggleMetaData() {
    if (this.metaDataEditor) { return this.closeMetaData(); }
    const template = await this.activeTemplate$.pipe(take(1)).toPromise();
    const metaData = await this.store.select(selectMetadata).pipe(take(1)).toPromise();
    if (template?.metaData) {
      this.loadMetaDataEditor(template.metaData, metaData);
    }
  }

  closeMetaData() {
    if (!this.metaDataEl || !this.metaDataEditor) { return; }
    const el = this.metaDataEl?.nativeElement;
    this.metaDataEditor.destroy();
    this.metaDataEditor = null;
    el.innerHTML = '';
  }

}
