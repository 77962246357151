import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'authorList'
})
export class AuthorListPipe implements PipeTransform {
    transform(authors: any[] | null, authorsCount = 2): string {
        if (authors == null) {
            return '';
        }

        const ret = authors.map((author): string => {
            return (author.firstName || author.given || '') + ' ' + (author.lastName || author.family || '');
        }).filter((authorName, index) => {
            return authorName.trim().length > 0 && index < authorsCount;
        }).join(', ');
        return authors.length <= authorsCount ? ret : ret + ' et al.';
    }
}
