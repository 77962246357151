import { createId } from '@sciflow/schema';
import { setBlockType } from 'prosemirror-commands';
import { Fragment } from 'prosemirror-model';
import { NodeSelection, TextSelection } from 'prosemirror-state';
import {
  addColumnAfter, addColumnBefore, addRowAfter, addRowBefore, CellSelection, deleteColumn, deleteRow, mergeCells, splitCell, toggleHeaderColumn, toggleHeaderRow
} from 'prosemirror-tables-ts';
import { insertPoint } from 'prosemirror-transform';

export enum TABLE_COMMANDS {
  'addColumnAfter' = 'addColumnAfter',
  'addColumnBefore' = 'addColumnBefore',
  'deleteColumn' = 'deleteColumn',
  'addRowAfter' = 'addRowAfter',
  'addRowBefore' = 'addRowBefore',
  'deleteRow' = 'deleteRow',
  'mergeCells' = 'mergeCells',
  'splitCell' = 'splitCell',
  'setCellAttr' = 'setCellAttr',
  'toggleHeaderRow' = 'toggleHeaderRow',
  'toggleHeaderColumn' = 'toggleHeaderColumn',
  'toggleHeaderCell' = 'toggleHeaderCell',
  'goToNextCell' = 'goToNextCell',
  'deleteTable' = 'deleteTable'
}

export enum ALIGN_COMMANDS {
  'alignLeft' = 'alignLeft',
  'alignRight' = 'alignRight',
  'alignCenter' = 'alignCenter',
  'alignJustify' = 'alignJustify'
}

export const tableCommands = [
  { id: TABLE_COMMANDS.toggleHeaderColumn, svgIcon: 'table-column-heading', tooltip: 'Toggle header column', run: toggleHeaderColumn, select: toggleHeaderColumn },
  { id: TABLE_COMMANDS.toggleHeaderRow, svgIcon: 'table-row-heading', tooltip: 'Toggle header row', run: toggleHeaderRow, select: toggleHeaderRow },
  { id: TABLE_COMMANDS.addColumnAfter, svgIcon: 'table-new-column-right', tooltip: 'Insert column right', run: addColumnAfter, select: addColumnAfter },
  { id: TABLE_COMMANDS.addColumnBefore, svgIcon: 'table-new-column-left', tooltip: 'Insert column left', run: addColumnBefore, select: addColumnBefore },
  { id: TABLE_COMMANDS.addRowAfter, svgIcon: 'table-new-row-below', tooltip: 'Add row below', run: addRowAfter, select: addRowAfter },
  { id: TABLE_COMMANDS.addRowBefore, svgIcon: 'table-new-row-above', tooltip: 'Add row before', run: addRowBefore, select: addRowBefore },
  { id: TABLE_COMMANDS.mergeCells, svgIcon: 'table-merge-cells', tooltip: 'Merge cells', run: mergeCells, select: mergeCells },
  { id: TABLE_COMMANDS.splitCell, svgIcon: 'table-split-cell', tooltip: 'Split cell', run: splitCell, select: splitCell },
  { id: TABLE_COMMANDS.deleteColumn, svgIcon: 'table-column-delete', tooltip: 'Delete column', run: deleteColumn, select: deleteColumn },
  { id: TABLE_COMMANDS.deleteRow, svgIcon: 'table-row-delete', tooltip: 'Delete row', run: deleteRow, select: deleteRow }
];

export const alignParagraph = (align: 'justify' | 'left' | 'center' | 'right') => {
  return (state, dispatch) => {

    if (!dispatch) {
      if (state.selection instanceof CellSelection) { return true; }
      if (state.selection && state.selection.$anchor.parent && state.selection.$anchor.parent.type.name === 'paragraph') { return true; }
    }

    const tr = state.tr;
    if (state.selection instanceof CellSelection) {
      // align all paragraps inside the cell
      for (let range of state.selection.ranges) {
        const from = range.$from.pos;
        const to = range.$to.pos;
        state.doc.descendants((node, pos) => {
          if (node.type.name === 'paragraph' && (pos >= from) && (pos <= to)) {
            tr.setNodeMarkup(pos, null, { ...node.attrs, 'text-align': align }, node.marks);
          }
        });
      }
      if (dispatch) {
        dispatch(tr);
      }
      return tr.docChanged;
    } else if (state.selection && state.selection.$anchor.parent && state.selection.$anchor.parent.type.name === 'paragraph') {
      // selection is going through one or more paragraphs
      state.doc.descendants((node, pos) => {
        const to = pos + node.nodeSize;
        if (state.selection.from < pos) { return; }
        if (state.selection.to > to) { return; }
        if (node.type.name === 'paragraph') {
          tr.setNodeMarkup(pos, null, { ...node.attrs, 'text-align': align }, node.marks);
        }
      });
      if (dispatch) {
        dispatch(tr);
      }
      return tr.docChanged;
    }
    return false;
  };
};

export const alignCommands = [
  { id: ALIGN_COMMANDS.alignLeft, svgIcon: 'table-text-align-left', tooltip: 'Align text left', run: alignParagraph('left'), select: alignParagraph('left') },
  { id: ALIGN_COMMANDS.alignCenter, svgIcon: 'table-text-align-center', tooltip: 'Center text', run: alignParagraph('center'), select: alignParagraph('center') },
  { id: ALIGN_COMMANDS.alignRight, svgIcon: 'table-text-align-right', tooltip: 'Align text right', run: alignParagraph('right'), select: alignParagraph('right') },
  { id: ALIGN_COMMANDS.alignJustify, svgIcon: 'table-text-align-justified', tooltip: 'Justify text', run: alignParagraph('justify'), select: alignParagraph('justify') }
];

export const highlight = (searchTerm: string) => (state, dispatch) => {

  const tr = state.tr;
  tr.setMeta('search-and-replace', searchTerm);

  if (dispatch) {
    dispatch(tr);
  }
};

export const insertHyperlink = (attrs?: { id?: string, href?: string; title?: string; }, text?: string) => {
  return (state, dispatch) => {

    const markType = state.schema.marks.anchor;
    function markActive(state, type) {
      let { from, $from, to, empty } = state.selection;
      if (empty) return type && type.isInSet(state.storedMarks || $from.marks());
      else return state.doc.rangeHasMark(from, to, type);
    }

    function markApplies(doc, ranges, type) {
      for (let i = 0; i < ranges.length; i++) {
        let { $from, $to } = ranges[i];
        let can = $from.depth == 0 ? doc.type.allowsMarkType(type) : false;
        doc.nodesBetween($from.pos, $to.pos, node => {
          if (can) return;
          can = node.inlineContent && node.type.allowsMarkType(type);
        });
        if (can) { return true; }
      }
      return false;
    }

    // if the selection has an active hyperlink mark, return.
    if (!markType || markActive(state, markType)) { return false; }

    if (attrs == null) { attrs = {}; } // in case the function is called with null a default wouldn't work
    if (!attrs.id) {
      attrs.id = createId();
    }
    if (!attrs.href) { attrs.href = '#'; }

    let content;
    // see if there was any selected content
    let { empty, $cursor, ranges } = state.selection;

    // we'll need to replace the selection before adding the mark
    if (text && text.length > 0) {
      content = state.schema.text(text, [state.schema.marks.anchor.create(attrs)]);
    } else if (empty && dispatch) {
      // we don't insert a hyperlink on empty content
      return false;
    }

    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType)) { return false; }

    if (dispatch) {
      const tr = state.tr;
      if (content) {
        // we just replace the selection with a new text
        const from = tr.selection.from;
        tr.replaceSelectionWith(content);
        tr.setSelection(TextSelection.create(tr.doc, from, from + content.nodeSize));
        $cursor = null;
        ranges = tr.selection.ranges;
        empty = false;
      }

      if ($cursor) {
        if (markType && markType.isInSet(state.storedMarks || $cursor.marks())) {
          dispatch(tr.removeStoredMark(markType));
        } else {
          dispatch(tr.addStoredMark(markType.create(attrs)));
        }
      } else {
        let has = false;
        for (let i = 0; !has && i < ranges.length; i++) {
          let { $from, $to } = ranges[i];
          has = tr.doc.rangeHasMark($from.pos, $to.pos, markType);
        }
        for (let i = 0; i < ranges.length; i++) {
          let { $from, $to } = ranges[i];
          if (has) { tr.removeMark($from.pos, $to.pos, markType); }
          else { tr.addMark($from.pos, $to.pos, markType.create(attrs)); }
        }
      }
      tr.scrollIntoView();
      dispatch(tr);
    }
    return true;
  };
};

export const updateAndFocusHyperlink = (id, attrs, text: string) => {
  return (state, dispatch) => {
    const tr = state.tr;
    const doc = tr.doc;
    let from, to;

    // resolve the first position where the mark appears
    doc.nodesBetween(0, doc.nodeSize - 2, (n, p) => {
      if (n.marks && n.marks.find(m => m.attrs.id === id)) {
        if (!from) {
          from = p;
        }
        to = p + n.nodeSize;
      }
    });

    if (!dispatch) { return from != null; }

    // const { from, to } = findMarkRange(resolvedPos, state.schema.marks.anchor);

    tr.replaceWith(from, to, state.schema.text(text, [state.schema.marks.anchor.create(attrs)]));
    tr.scrollIntoView();
    dispatch(tr);

    return true;
  };
};

export const removeHyperlink = (id: string) => {
  return (state, dispatch) => {
    const tr = state.tr;
    const doc = tr.doc;
    let node;
    let pos;

    doc.nodesBetween(0, doc.nodeSize - 2, (n, p) => {
      if (n.marks && n.marks.find(m => m.attrs.id === id)) {
        node = n;
        pos = p;
      }
    });

    if (node == null) { return false; }
    else if (!dispatch) { return true; }

    tr.removeMark(pos, pos + node.nodeSize, state.schema.marks.anchor);

    if (dispatch) {
      return dispatch(tr);
    }
  };
};

export const insertTable = (opts = { cols: 3, rows: 3, hasHeaderRow: true }) => {
  return (state, dispatch) => {

    const schema = state.schema;

    const id = createId();
    const attrs = {
      id
    };

    const insertAt = insertPoint(state.doc, state.selection.from, schema.nodes['table']);

    if (insertAt == null) { return false; }

    if (dispatch) {
      function createRow(cols: number, isHeader = false) {
        const c = [] as any;
        for (let i = 0; i < cols; i++) {
          c.push(schema.node(isHeader ? 'table_header' : 'table_cell', {}, [schema.node('paragraph', {}, [])]));
        }
        return schema.node('table_row', {}, c);
      }

      const rows = [] as any;
      for (let i = 0; i < opts.rows; i++) {
        rows.push(createRow(opts.cols, i === 0 && opts.hasHeaderRow));
      }

      dispatch && dispatch(state.tr.insert(insertAt, schema.node('table', attrs, rows)));
    }
    return true;
  };
};

export const insertCaption = (tablenode, pos) => {
  return (state, dispatch) => {

    const schema = state.schema;
    const tr = state.tr;
    const start = pos;
    const end = pos + tablenode.nodeSize;

    let insertAt;
    tr.doc.descendants((node, pos) => {
        if (pos > start && pos < end) {
            if (node.type.name === 'caption') {
                insertAt = insertPoint(tr.doc, pos + 1, schema.nodes.paragraph);
            }
        }
    });

    if (insertAt) {
      tr.insert(insertAt, schema.nodes.paragraph.create({}, []));
      tr.setSelection(TextSelection.create(tr.doc, insertAt + 1));
    }

    if (dispatch) {
      tr.scrollIntoView();
      dispatch(tr);
    }

    return true;
  };
};

export const insertFigure = (type = 'figure', id: string = createId(), attributes: any = {}) => {
  return (state, dispatch) => {

    const attrs = {
      id,
      src: null,
      alt: null,
      type,
      ...attributes
    };

    const insertAt = insertPoint(state.doc, state.selection.from, state.schema.nodes['figure']);

    if (insertAt == null) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;
      tr.insert(
        insertAt,
        state.schema.node('figure', attrs, [
          state.schema.node('caption', {}, [
            state.schema.node('paragraph')
          ])
        ])
      );
      tr.setSelection(NodeSelection.create(tr.doc, insertAt + 2));
      tr.scrollIntoView();
      dispatch(tr);
    }

    return true;
  };
};
export const insertFootnote = (footnoteContent, id?: string) => {
  return (state, dispatch) => {

    const schema = state.schema;
    const insertAt = insertPoint(state.doc, state.selection.from, schema.nodes['footnote']);

    if (insertAt == null) {
      return false;
    }

    if (!id) {
      id = createId();
    }

    const attrs = {
      id,
      type: 'footnote'
    };

    let content = Fragment.from([schema.text(' ')]);
    // see if there was any selected content
    const { empty, $from, $to, from } = state.selection;
    if (!empty && $from.sameParent($to) && $from.parent.inlineContent) {
      content = $from.parent.content.cut($from.parentOffset, $to.parentOffset);
    }

    if (dispatch) {
      const tr = state.tr.replaceSelectionWith(schema.node('footnote', attrs, footnoteContent?.content || content));
      tr.scrollIntoView();
      dispatch(tr);
    }

    return true;
  };
};

export const insertCode = (id: string) => {
  return (state, dispatch) => {

    if (!id) {
      id = createId();
    }

    const attrs = {
      id,
      tex: ''
    };

    // code is technically an inline element but should not appear in headings
    const resolvedPos = state.doc.resolve(state.selection.from);
    if (resolvedPos.parent && resolvedPos.parent.type.name === 'heading') {
      return false;
    }

    const insertAt = insertPoint(state.doc, state.selection.from, state.schema.nodes['code']);
    if (insertAt === null) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;
      tr.insert(insertAt, state.schema.node('code', attrs, [state.schema.text(` `)]));
      tr.scrollIntoView();

      dispatch(tr);
    }

    return true;
  };
};

export const insertMath = (id: string) => {
  return (state, dispatch) => {

    const schema = state.schema;

    if (!id) {
      id = createId();
    }

    const attrs = {
      id,
      tex: ''
    };

    // math is technically an inline element but should not appear in headings
    const resolvedPos = state.doc.resolve(state.selection.from);
    if (resolvedPos.parent && resolvedPos.parent.type.name === 'heading') {
      return false;
    }

    const insertAt = insertPoint(state.doc, state.selection.from, schema.nodes['math']);
    if (insertAt === null) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;
      tr.insert(insertAt, schema.node('math', attrs, [schema.text(` `)]));
      tr.scrollIntoView();

      dispatch(tr);
    }

    return true;
  };
};

export const insertPageBreak = () => {
  return (state, dispatch) => {
    const schema = state.schema;
    const insertAt = insertPoint(state.doc, state.selection.from, schema.nodes['pageBreak']);

    if (insertAt == null) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;
      tr.insert(
        insertAt,
        schema.node('pageBreak')
      );
      dispatch(tr);
    }

    return true;
  };
};

export const getStructureCommands = (schema) => {
  const changeToParagraph = setBlockType(schema.nodes.paragraph, {});

  const createHeading = (level: number) => (state, dispatch?) => {
    return setBlockType(schema.nodes.heading, { level })(state, (tr) => {
      if (dispatch) {
        dispatch(tr);
      }
    });
  };

  const heading = (level) => ({
    id: 'h' + level, run: (state, dispatch) => {
      return createHeading(level)(state, dispatch);
    }, select: (state) => {
      return createHeading(level)(state);
    }
  });


  return [
    { id: 'p', run: changeToParagraph, select: changeToParagraph },
    heading(1), heading(2), heading(3), heading(4), heading(5)];
};


