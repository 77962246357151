import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SourceField } from '@sciflow/cite';
import { ProjectService } from 'projects/reference-app/src/editor/services/project.service';
import { Subject, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FileService } from 'shared';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { RenderingService } from '../rendering.service';
import { setReferences } from '../store/references.actions';
import { selectCitationlist, selectReferenceList } from '../store/references.reducer';

@Component({
  selector: 'lib-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferencesComponent implements OnDestroy {

  displayedColumns: string[] = ['bib', 'isStarred'];
  stop$ = new Subject();
  loading = true;
  projectId: string;

  /** HTML element we use for as a drag image */
  dragElement;

  referenceList$ = this.store.select(selectReferenceList).pipe(
    switchMap(
      referenceList => this.store.select(selectCitationlist).pipe(
        map((nodeCitations) => ({ nodeCitations, referenceList })))
    ),
    map(({ nodeCitations, referenceList }) => {

      const citations: any[] = [];
      if (nodeCitations.length !== 0) {
        nodeCitations.forEach(element => {
          element.citationItems.map((ref) => citations.push({ source: ref.id, id: element.citationID, textContent: element.plainCitation }));
        });
      }

      if (referenceList.length === 0) { this.loading = false; return []; }

      referenceList = referenceList.map((reference: any) => {
        const referencesInText = [] as any;
        reference = { ...reference };
        citations.map(citation => {
          if (citation.source === reference.csl.id) {
            reference.citationsfromEditor = [];
            referencesInText.push(citation);
            reference.citationsfromEditor = referencesInText;
          }
        });
        return reference;
      });

      return referenceList as any;
    })
  );

  @ViewChild('fileUpload', { static: true }) fileUpload;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private renderingService: RenderingService,
    private fileService: FileService,
    private router: Router,
    private projectService: ProjectService,
    private errorDialogService: ErrorDialogService
  ) {
  }

  async uploadReferenceFile(): Promise<void> {
    this.fileUpload.nativeElement.click();
  }

  public async onUpload(event) {
    event.preventDefault();
    event.stopPropagation();
    const file = (event.srcElement || event.target).files.item(0);
    this.fileUpload.nativeElement.value = '';

    if (!file.name.endsWith('.bib')) {
      this.errorDialogService.openDialog('Only .bib files are currently supported.');
      return;
    }

    const result = await this.fileService.uploadFile(this.projectId, file);
    if (result) {
      const references = await this.projectService.getReferences(result.projectId);
      this.store.dispatch(setReferences({ references }));
    }
  }

  editCitation(citation): void {
    const element = document.getElementById(citation.id);
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    });
    element?.focus();
    this.router.navigate(['citation', citation.id], { relativeTo: this.activatedRoute });
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  async dragStart(event, data): Promise<void> {
    if (!data.csl?.id) {
      this.errorDialogService.openDialog('No citation data found');
      return;
    }
    const citation = this.renderingService.render([{ id: data.csl.id }], [data.csl]);
    const source = { citationItems: [{ id: data.csl.id as string }], properties: { noteIndex: 0 } };
    const cite = `<cite data-source="${encodeURI(SourceField.toString(source))}">${citation}</cite>`;

    this.dragElement = document.createElement('cite');
    this.dragElement.innerHTML = `<b>${citation}</b>`;
    document.body.appendChild(this.dragElement);
    event.dataTransfer.setDragImage(this.dragElement, 0, 25);

    event.dataTransfer.setData('text/html', cite);
  }

  dragEnd(event, row): void {
    if (this.dragElement) {
      document.body.removeChild(this.dragElement);
    }
  }

}
