import { Injector, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { DocumentOutlineService } from '../document-outline.service';
import { selectPart } from '../store/outline.reducer';
import { NodeView } from 'prosemirror-view';

const linkIcon = `<span class="material-icons">link</span>`;

export class LinkView implements NodeView {
  /** The main dom element. */
  dom;
  // chip
  chip;
  // tooltip
  tooltipWrapper;
  tooltip;

  private renderer: Renderer2 = this.injector.get(Renderer2);
  documentOutlineService: DocumentOutlineService = this.injector.get(DocumentOutlineService);

  stop$ = new Subject<any>();

  nodeText = '';
  nodeUpdate$ = new Subject<{ id: string }>();

  /**
   * Creates an instance of LinkView.
   * @param {*} node
   * @param {Injector} injector
   * @memberof LinkView
   */
  constructor(private node: any, private injector: Injector, private options: { store: Store }) {
    // create chip
    this.chip = this.renderer.createElement('span');
    this.chip.innerHTML = 'xref';
    this.renderer.addClass(this.chip, 'js-cross-referencing-chip-missing-ref');
    this.renderer.setAttribute(this.chip, 'contentEditable', 'false');
    this.renderer.setAttribute(this.chip, 'draggable', 'false');

    // create tooltip
    this.tooltip = this.renderer.createElement('div');
    this.renderer.setAttribute(this.tooltip, 'contentEditable', 'false');

    // create tooltipWrapper
    // We need a wrapper to postion the actual tolltip centered (see also styling of js-cross-referencing-chip-tooltip)
    this.tooltipWrapper = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipWrapper, 'js-cross-referencing-chip-tooltip');

    // create dom element (which is the root for the node)
    this.dom = this.renderer.createElement('div');
    this.renderer.addClass(this.dom, 'js-cross-referencing-chip-wrapper');

    // and marry them together
    this.renderer.appendChild(this.tooltipWrapper, this.tooltip);

    this.renderer.appendChild(this.dom, this.chip);
    this.renderer.appendChild(this.dom, this.tooltipWrapper);

    // add chip event listeners
    this.renderer.listen(this.chip, 'click', () => {
      this.documentOutlineService.focusElement(this.node.attrs.href.replace('#', ''));
    });
    this.renderer.listen(this.chip, 'mouseenter', () => {
      this.nodeText = this.nodeText ? this.nodeText : 'Referenced item deleted';
      this.tooltip.innerText = this.nodeText;
      this.tooltip.style.display = 'block';
    });
    this.renderer.listen(this.chip, 'mouseleave', () => {
      this.tooltip.style.display = 'none';
    });

    this.nodeUpdate$.next({ id: node.attrs.href.replace('#', '') });
    requestAnimationFrame(() => this.getLabel());
  }

  async getLabel() {
    // wait until at least one heading is loaded

    const id = this.node.attrs.href.replace('#', '');
    const label = await this.options.store.select(selectPart).pipe(
      map(elements => elements?.find(e => e.id === id)),
      filter((element) => element != null),
      take(1)
    ).toPromise();

    this.updateNodeText(label);
  }

  /**
   * Called when a node is updated within the editor.
   * @param {any} node
   * @param {any} decorations
   * @returns {boolean}
   * @memberof LinkView
   */
  update(node, decorations): boolean {
    if (!node.sameMarkup(this.node)) { return false; }
    if (node.attrs.href && node.attrs.href !== this.node.attrs.href) {
      this.node = node;
      this.nodeUpdate$.next({ id: node.attrs.href.replace('#', '') });
      this.getLabel();
    }
    return true;
  }

  destroy() {
    this.stop$.next();
  }

  renderLabel(element) {
    this.renderer.removeClass(this.chip, 'js-cross-referencing-chip-missing-ref');
    this.renderer.addClass(this.chip, 'js-cross-referencing-chip');
    this.nodeText = element.type.toUpperCase() + ' ' + element.label;
    return element.label || 'untitled';
  }

  /**
   * Updates the tooltip text
   * @private
   * @param {any} node
   * @returns {string}
   * @memberof LinkView
   */
  private updateNodeText(element): void {
    if (!element) {
      this.renderer.addClass(this.chip, 'js-cross-referencing-chip-missing-ref');
      this.nodeText = 'Referenced item not found';
    } else {
      switch (element.type) {
        case 'code':
          {
            const label = this.renderLabel(element);
            this.chip.innerHTML = `${linkIcon}&nbsp;cod. ${label.length > 10 ? (label.substr(0, 10) + '..') : label}`;
          }
          break;
        case 'table':
          {
            const label = this.renderLabel(element);
            this.chip.innerHTML = `${linkIcon}&nbsp;tab. ${label.length > 10 ? (label.substr(0, 10) + '..') : label}`;
          }
          break;
        case 'figure':
          {
            const label = this.renderLabel(element);
            this.chip.innerHTML = `${linkIcon}&nbsp;fig. ${label.length > 10 ? (label.substr(0, 10) + '..') : label}`;
          }
          break;
        case 'Equation':
          {
            const label = this.renderLabel(element);
            this.chip.innerHTML = `${linkIcon}&nbsp;eq. ${label.length > 10 ? (label.substr(0, 10) + '..') : label}`;
          }
          break;
        default:
          {
            this.renderer.removeClass(this.chip, 'js-cross-referencing-chip-missing-ref');
            this.renderer.addClass(this.chip, 'js-cross-referencing-chip');
            const level = parseInt(element.level);
            const label = element.label || 'untitled';
            this.nodeText = 'H' + level + ' ' + element.label;
            this.chip.innerHTML = `${linkIcon}&nbsp;h${level}. ${label.length > 10 ? (label.substr(0, 10) + '..') : label}`;
          }
          break;
      }
      this.renderer.removeClass(this.chip, 'js-cross-referencing-chip-missing-ref');
    }
  }
}
