import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '../editor/error-handler/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/write', pathMatch: 'full' },
  { path: 'write', loadChildren: () => import('../editor/editor.module').then(m => m.EditorModule) },
  { path: '404', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: false, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
