import { NgModule } from '@angular/core';
import { SharedComponent } from './shared.component';
import { TruncatePipe } from './truncate.pipe';



@NgModule({
  declarations: [
    SharedComponent,
    TruncatePipe
  ],
  imports: [
  ],
  exports: [
    SharedComponent,
    TruncatePipe
  ]
})
export class SharedModule { }
