/**
 * SFO uses citeproc-js for reference formating.
 * @see https://citeproc-js.readthedocs.io/en/latest/
 */

/** Custom formats that require a combination or options to be set
 * default/null: (Lastname, 2021)
 * suppress-author: 2021
 * author-only: lastname
 * combined: Author 2021
*/
export type customDisplayFormat = null | 'suppress-author' | 'author-only' | 'combined';

 export interface CSLReference {
    id: string;
    [key: string]: any;
}

/** Just like a citation but with a mandatory citationID */
export interface CitationWithId extends Citation {
    /** a mandatory citation id */
    citationID: string;
    plainCitation?: string;
}

/** A citation (e.g. (Smith 2021)) */
export interface Citation {
    /** A unique identifier added by the engine (thus optional) */
    citationID?: string;
    /** List of one or more citation items (order is important) */
    citationItems: CitationItem[];
    properties: {
        mode?: 'composite';
        /** the footnote number a citation appears in (0 for main document body) */
        noteIndex: number;
        /** An infix like 's early work */
        infix?: string;
        /** The rendered citation as a string */
        plainCitation?: string;
    }
}

/**
 * Options used to change how a citation is displayed.
 */
export interface CitationItemOptions {
    /** Do not include the author name */
    'suppress-author'?: boolean;
    /** Do only include the author name */
    'author-only'?: boolean;
}

/**
 * A citation item
 * @see https://citeproc-js.readthedocs.io/en/latest/csl-json/markup.html#cite-items
 */
export interface CitationItem extends CitationItemOptions {
    /** The reference id this citation is made for */
    id: string;
    /** The page/chapter where to reference */
    locator?: string;
    /** A label like page for the locator @see https://docs.citationstyles.org/en/stable/specification.html#locators */
    label?: string;
    prefix?: string;
    suffix?: string;
    /** Optional attribute to indicate the index of this citation within all citations */
    position?: number;
    /** Whether the same reference was made near this one (style dependent). */
    'near-note'?: boolean;
    /** The Zotero JSON for the reference (if available) */
    itemData?: any;
}

export interface CitationCache {
    index: number;
    renderedCitation: string;
    citationID: string;
}

export type CitationIDNoteIndexPair = [citationID: string, noteIndex: number];

/**
 * Old format used by SciFlow to encode a citation.
 */
export interface LecacyReference {
    /** the reference ids as an array seperated by , (order is important) */
    ids: string[];
    /** the citation item information for each id (may be empty) */
    options?: {
        [key: string]: CitationItem;
    }
}