import { Action, ActionReducer, createReducer, createSelector, on } from '@ngrx/store';
import * as exportActions from './export.actions';

export const exportFeatureKey = 'export';

export interface Export {
    template: any;
    metaData: object;
}

const initialState: Export = {
    template: undefined,
    metaData: {}
};

export const exportReducer = createReducer(
    initialState,
    on(exportActions.updateTemplate, (state, action) => ({ ...state, template: action.template })),
    on(exportActions.updateMetadata, (state, action) => ({ ...state, metaData: action.metaData }))
);

export const selectExportState = (state): Export => state.export;

/**
 * Selects the template
 */
export const selectTemplate = createSelector(
    selectExportState,
    (state: Export) => state.template
);

/**
 * Selects the meta data
 */
export const selectMetadata = createSelector(
    selectExportState,
    (state: Export) => state.metaData
);

export const exportMetadataReducer: ActionReducer<Export, any> = (state: Export | undefined, action: Action) => {
    return exportReducer(state, action);
};
