/**
 * Schema for ProseMirror.
 */
import { Schema, NodeSpec, MarkSpec } from 'prosemirror-model';
import { marks as basicMarks, nodes as basicNodes } from 'prosemirror-schema-basic';
import { addListNodes } from 'prosemirror-schema-list';
import { tableNodes } from 'prosemirror-tables-ts';

import { SFNodeType, SFMarkType } from './types';

export const createId = (): string => 'abcdefghijklmnopqrstuvwxyz'.charAt(Math.floor(Math.random() * 26)) + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(Math.random() * 52)) + Math.random().toString(36).substr(2, 12);

let sciflowBaseSchema, manuscriptSchema, inlineSchema, documentTitleSchema, titleOnlySchema;

try {

  // all ids must start with a letter (for CSS styling to be able to target IDs)
  const idAttr = { default: null }; // ids are generated by the id plugin
  const readID = (dom) => ({ id: dom.getAttribute('id') || dom.getAttribute('data-id') });

  function extend(obj, fields) {
    return Object.assign({}, obj, fields);
  }

  const document: NodeSpec = {
    attrs: { type: { default: 'article' }, lang: { default: '' } },
    toDOM() { return ['section', 0]; },
    parseDOM: [{ tag: 'section' }],
    content: 'block*'
  };

  const documentWithTitle: NodeSpec = {
    attrs: { type: { default: 'article' }, lang: { default: '' } },
    toDOM() { return ['section', 0]; },
    parseDOM: [{ tag: 'section' }],
    content: 'header? block*'
  };

  const header: NodeSpec = {
    content: 'heading subtitle?',
    marks: 'sub sup em strong',
    isolating: true,
    toDOM() { return ['header', 0]; },
    parseDOM: [{ tag: 'header' }]
  };

  const heading = (content = 'text*'): NodeSpec => ({
    content,
    group: 'block',
    marks: 'sub sup em strong',
    defining: false,
    selectable: false,
    attrs: { id: idAttr, level: { default: 1 }, role: { default: '' }, type: { default: 'chapter' }, numbering: { default: '' } },
    toDOM(node) {
      return ['h' + node.attrs.level, {
        id: node.attrs.id,
        'data-id': node.attrs.id,
        'data-level': node.attrs.level,
        'data-type': node.attrs.type,
        'data-role': node.attrs.role,
        'data-numbering': node.attrs.numbering
      }, 0];
    },
    parseDOM: [
      {
        tag: 'h1',
        // @ts-ignore can not be string
        getAttrs: (dom: HTMLElement) => ({
          ...readID(dom),
          level: 1,
          type: dom.getAttribute('data-type'),
          numbering: dom.getAttribute('data-numbering'),
          role: dom.getAttribute('data-role')
        })
      },
      { tag: 'h2', getAttrs: (dom) => ({ ...readID(dom), level: 2 }) },
      { tag: 'h3', getAttrs: (dom) => ({ ...readID(dom), level: 3 }) },
      { tag: 'h4', getAttrs: (dom) => ({ ...readID(dom), level: 4 }) },
      { tag: 'h5', getAttrs: (dom) => ({ ...readID(dom), level: 5 }) },
      { tag: 'h6', getAttrs: (dom) => ({ ...readID(dom), level: 6 }) }
    ]
  });

  const subtitle: NodeSpec = {
    content: 'inline*',
    marks: '_',
    toDOM() { return ['h2', { 'data-type': 'subtitle' }, 0]; },
    parseDOM: [{ tag: 'h2[data-type=subtitle]' }]
  };

  const paragraph: NodeSpec = {
    content: 'inline*',
    marks: '_',
    group: 'block',
    attrs: { id: idAttr, 'text-align': { default: null } },
    toDOM(node) {
      const attrs = { 'data-id': node.attrs.id, id: node.attrs.id };
      if (node.attrs['text-align']) {
        attrs['text-align'] = node.attrs['text-align'];
      }
      return ['p', attrs, 0];
    },
    parseDOM: [{ tag: 'p', getAttrs: readID }]
  };

  const sidebar: NodeSpec = {
    content: 'heading? block+',
    toDOM() { return ['aside', { 'data-type': 'sidebar', class: 'htmlbook-box' }, 0]; },
    parseDOM: [{ tag: 'aside' }]
  };

  // export const admonitionTypes = ['note', 'warning', 'tip', 'caution', 'important'];

  // const admonition = {
  //   content: 'heading? block+',
  //   group: 'block',
  //   attrs: { type: {} },
  //   toDOM(node) { return ['div', { 'data-type': node.attrs.type, class: 'htmlbook-box' }, 0]; },
  //   parseDOM: admonitionTypes.map(type => ({ tag: `div[data-type=${type}]`, getAttrs: () => ({ type }) }))
  // };

  const footnote: NodeSpec = {
    group: 'inline',
    content: 'inline*',
    inline: true,
    selectable: false,
    // setting draggable to false since true will expose odd behavior in safari @see https://github.com/ProseMirror/website/issues/86
    draggable: false,
    // This makes the view treat the node as a leaf, even though it
    // technically has content
    atom: true,
    attrs: { id: idAttr, type: { default: 'footnote' } },
    toDOM(node) { return ['span', { 'data-type': 'footnote', 'data-id': node.attrs.id, id: node.attrs.id }, 0]; },
    parseDOM: [{ tag: 'span[data-type=\'footnote\']', getAttrs: readID }]
  };

  /**
   * A figure that may contain tables, images or other environments.
   * type should be one of: image, native-table, image-table
   */
  const figure: NodeSpec = {
    content: '(table|code_block)? caption',
    group: 'block',
    draggable: true,
    selectable: true,
    defining: true,
    isolating: true,
    attrs: { 'id': idAttr, 'src': { default: '' }, 'alt': { default: '' }, 'type': { default: 'figure' }, orientation: { default: 'portrait' } },
    toDOM(node) {
      return ['figure', {
        'data-id': node.attrs['id'],
        id: node.attrs.id,
        'data-type': node.attrs['type'],
        'data-alt': node.attrs['alt'],
        'data-src': node.attrs['src'],
        'data-orientation': node.attrs['orientation']
      }, 0];
    },
    parseDOM: [{
      tag: 'figure',
      // @ts-ignore can not be string
      getAttrs: (dom: HTMLElement) => {
        return {
          id: dom.getAttribute('data-id'),
          src: dom.getAttribute('data-src'),
          alt: dom.getAttribute('data-alt'),
          orientation: dom.getAttribute('data-orientation'),
          type: dom.getAttribute('data-type')
        };
      }
    }],

  };

  const caption: NodeSpec = {
    content: 'block*',
    marks: '_',
    toDOM() { return ['figcaption', 0]; },
    parseDOM: [{ tag: 'figcaption' }]
  };

  const pageBreak: NodeSpec = {
    group: 'block',
    selectable: false,
    draggable: true,
    toDOM() { return ['div', { 'data-type': 'page-break' }] },
    // @ts-ignore can not be string
    parseDOM: [{ tag: 'div[data-type="page-break"]', getAttrs: (dom: HTMLElement) => ({ 'data-type': dom.getAttribute('data-type') }) }]
  };

  const code: NodeSpec = {
    content: 'text*',
    marks: '',
    code: true,
    defining: true,
    group: "block",
    attrs: { id: idAttr, text: { default: "" }, 'type': { default: 'code' }, language: { default: "text/plain" } },
    parseDOM: [
      // @ts-ignore can not be string
      { tag: "pre", getAttrs: (dom: HTMLElement) => ({ text: dom.textContent, language: dom.getAttribute("data-language") || "text/plain" }) },
    ],
    toDOM(node) {
      return ["pre", { "data-language": node.attrs.language }, node.attrs.text]
    }
  };

  const math: NodeSpec = {
    group: 'inline',
    content: 'text*',
    inline: true,
    code: true,
    draggable: true,
    defining: true,
    atom: true,
    attrs: { id: idAttr, tex: { default: '' }, style: { default: 'inline' } },
    toDOM(node) { return ['math', { 'data-id': node.attrs.id, id: node.attrs.id, 'data-tex': node.attrs.tex, 'data-style': node.attrs.style }, 0]; },
    // @ts-ignore can not be string
    parseDOM: [{ tag: 'math', getAttrs: (dom: HTMLElement) => ({ id: dom.getAttribute('data-id'), tex: dom.getAttribute('data-tex'), style: dom.getAttribute('data-style') }), preserveWhitespace: 'full' }]
  };

  const citation: NodeSpec = {
    attrs: { source: { default: null }, style: { default: 'apa' }, id: idAttr },
    inline: true,
    content: 'text*',
    draggable: true,
    selectable: true,
    isolating: false,
    atom: true,
    group: 'inline',
    toDOM(node) {
      return ['cite', { 'data-source': node.attrs.source, 'data-style': node.attrs.style }, 0];
    },
    parseDOM: [{
      tag: 'cite[data-source]',
      // @ts-ignore can not be string
      getAttrs(dom: HTMLElement) {
        return { source: dom.getAttribute('data-source'), style: dom.getAttribute('data-style') };
      }
    }]
  };

  const anchor: MarkSpec = {
    attrs: { href: { default: null }, title: { default: null }, id: { default: null } },
    content: 'inline*',
    inline: true,
    selectable: false,
    defining: true,
    group: 'inline',
    toDOM(node) {
      return ['a', { 'href': node.attrs.href, title: node.attrs.title, 'data-id': node.attrs.id }, 0];
    },
    parseDOM: [{
      tag: 'a[href]:not([data-type=xref])',
      // @ts-ignore can not be string
      getAttrs(dom: HTMLElement) {
        return { href: dom.getAttribute('href'), title: dom.getAttribute('title'), id: dom.getAttribute('data-id') };
      }
    }]
  };

  const image: NodeSpec = {
    inline: true,
    attrs: {
      src: { default: null },
      alt: { default: null },
      title: { default: null },
      id: idAttr,
      metaData: { default: null }
    },
    group: 'inline',
    draggable: true,
    parseDOM: [{
      // @ts-ignore can not be string
      tag: 'img[src]', getAttrs(dom: HTMLElement) {
        return {
          src: dom.getAttribute('src'),
          title: dom.getAttribute('title'),
          alt: dom.getAttribute('alt'),
          ...readID(dom)
        }
      }
    }],
    toDOM(node) { let { src, alt, title, id } = node.attrs; return ['img', { src, alt, title, id }] }
  };

  const link: NodeSpec = {
    attrs: { type: {}, href: {} },
    inline: true,
    content: 'text*',
    group: 'inline',
    selectable: false,
    draggable: true,
    toDOM(node) {
      return ['a', { 'data-type': node.attrs.type, 'href': node.attrs.href, 'reference-format': node.attrs['reference-format'] }, 0];
    },
    parseDOM: [{
      tag: 'a[href][data-type=xref]',
      // @ts-ignore
      getAttrs(dom: HTMLElement) {
        return { type: dom.getAttribute('data-type'), href: dom.getAttribute('href'), 'reference-format': dom.getAttribute('reference-format') };
      }
    }]
  };

  const horizontal_rule: NodeSpec = {
    group: 'block',
    parseDOM: [{ tag: 'hr' }],
    toDOM() { return ['hr'] }
  };

  // Marks
  const superscriptMark: MarkSpec = {
    toDOM() { return ['sup']; },
    parseDOM: [{ tag: 'sup' }]
  };

  const subscriptMark: MarkSpec = {
    toDOM() { return ['sub']; },
    parseDOM: [{ tag: 'sub' }]
  };

  titleOnlySchema = new Schema({
    nodes: {
      doc: extend(document, { content: 'header' }),
      header,
      heading: heading(),
      subtitle,
      text: basicNodes.text
    },
    marks: {
      sup: superscriptMark,
      sub: subscriptMark,
      em: basicMarks.em,
      strong: basicMarks.strong
    }
  } as any);

  const tableNodeList = tableNodes({
    tableGroup: 'block',
    cellContent: '(paragraph | ordered_list | bullet_list | figure)*',
    cellAttributes: {
      background: {
        default: null,
        getFromDOM(dom: Element) { return (dom as HTMLElement).style.backgroundColor || null },
        setDOMAttr(value, attrs) { if (value) attrs.style = (attrs.style || '') + `background-color: ${value};` }
      }
    }
  });

  tableNodeList.table.attrs = {
    ...(tableNodeList.table.attrs || {}),
    id: idAttr
  };

  const marks: { [key: string]: MarkSpec } = {
    [SFMarkType.anchor]: anchor,
    [SFMarkType.emphasis]: basicMarks.em,
    [SFMarkType.strong]: basicMarks.strong,
    [SFMarkType.superscript]: superscriptMark,
    [SFMarkType.subscript]: subscriptMark
  };

  const nodes: { [key: string]: NodeSpec } = {
    [SFNodeType.paragraph]: paragraph,
    [SFNodeType.heading]: heading('(text | footnote)*'),
    [SFNodeType.subtitle]: subtitle,
    [SFNodeType.header]: header,
    [SFNodeType.document]: documentWithTitle,
    [SFNodeType.image]: image,
    [SFNodeType.horizontalRule]: horizontal_rule,
    [SFNodeType.blockquote]: basicNodes.blockquote,
    [SFNodeType.pageBreak]: pageBreak,
    // sidebar,
    // admonition,
    // iframe,
    [SFNodeType.caption]: caption,
    // mention,
    // example,
    [SFNodeType.code]: code,
    [SFNodeType.math]: math,
    [SFNodeType.text]: basicNodes.text,
    [SFNodeType.hardBreak]: basicNodes.hard_break,
    [SFNodeType.citation]: citation,
    [SFNodeType.link]: link,
    [SFNodeType.footnote]: footnote
  };

  const baseSchema = new Schema({
    nodes,
    marks
  });

  let htmlBookSchemaNodes = addListNodes(baseSchema.spec.nodes as any, 'block*', 'block');

  sciflowBaseSchema
    = new Schema({
      nodes: htmlBookSchemaNodes
        // @ts-ignore table repo not typed
        .append(tableNodeList)
        .append({ figure }),
      marks
    });

  manuscriptSchema
    = new Schema({
      nodes: htmlBookSchemaNodes
        // @ts-ignore tables not typed
        .append(tableNodeList)
        .append({ figure }),
      marks
    });

  documentTitleSchema = new Schema({
    nodes: {
      doc: header,
      heading: heading(),
      subtitle,
      text: basicNodes.text
    },
    marks: {
      sup: superscriptMark,
      sub: subscriptMark,
      em: basicMarks.em,
      strong: basicMarks.strong
    }
  } as any);

  inlineSchema = new Schema({
    nodes: {
      doc: paragraph,
      text: basicNodes.text,
      citation
    },
    marks: {
      sup: superscriptMark,
      sub: subscriptMark,
      em: basicMarks.em,
      strong: basicMarks.strong
    }
  } as any);


} catch (e: any) {
  console.error('Could not create schemas', e.message);
  throw e;
}

const schemas: { [name: string]: Schema } = {
  manuscript: manuscriptSchema,
  chapter: sciflowBaseSchema,
  title: documentTitleSchema,
  inline: inlineSchema
};

export {
  sciflowBaseSchema,
  titleOnlySchema,
  documentTitleSchema,
  schemas
};
