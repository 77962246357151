/**
 * ProseMirror node names
 */
export enum SFNodeType {
    /** the root node */
    document = 'doc',
    /** A header containing a heading and a subtitle
     * @deprecated
     */
    header = 'header',
    paragraph = 'paragraph',
    text = 'text',
    math = 'math',
    footnote = 'footnote',
    citation = 'citation',
    quote = 'quote',
    /** A forced page break */
    pageBreak = 'pageBreak',
    hardBreak = 'hard_break',
    blockquote = 'blockquote',
    caption = 'caption',
    ordered_list = 'ordered_list',
    bullet_list = 'bullet_list',
    /** Links used for cross referencing (@see MarkType.anchor for hyperlinks) */
    link = 'link',
    list_item = 'list_item',
    heading = 'heading',
    figure = 'figure',
    image = 'image',
    table = 'table',
    table_row = 'table_row',
    table_cell = 'table_cell',
    table_header = 'table_header',
    subtitle = 'subtitle',
    horizontalRule = 'horizontal_rule'
};

export enum SFMarkType {
    emphasis = 'em',
    strong = 'strong',
    superscript = 'sup',
    subscript = 'sub',
    code = 'code',
    /** an anchor (hyperlink) */
    anchor = 'anchor'
};
