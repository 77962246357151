import { CSLReference } from "@sciflow/cite";
import { Author, DocumentNode, SFNodeType } from "./types";
import romanize from 'romanize';

/***
 * A snapshot of the document (without file content).
 */
export interface DocumentSnapshot {
    /** A unique identifier (may be a technical key) */
    id?: string;
    /** The API version of the snapshot */
    apiVersion: 1;
    /** The locale (e.g. en-US) */
    locale?: string;
    /** A unique string that identifies the document (ideally UUID v4) */
    documentId: string;
    /** Date as milliseconds since the epoch. */
    createdAt: number;
    /** Author list */
    authors: Author[];
    /** A list of all document parts in order */
    index: {
        index: string[];
    };
    /** The document title part */
    title: DocumentSnapshotPart;
    parts: DocumentSnapshotPart[];
    references: CSLReference[];
    /** The publication meta data set in the document (e.g. a DOI, custom margins etc.) */
    metaData: any;
    files: DocumentSnapshotResource[];
}

/** A part of the document that may be edited independently.
 * Only partId and document are mandatory.
*/
export interface DocumentSnapshotPart {
    /** A unique identifier (may be a technical key) */
    id?: string;
    /** A unique identifier that is referenced in the document index (also partId) */
    partId: string;
    /** The locale (e.g. en-US) */
    locale?: string;
    /** The part title (h1) */
    title?: string;
    /** ProseMirror document node */
    document: DocumentNode<SFNodeType.document>;
    /** The type of the part. Defaults to chapter */
    type?: 'abstract' | 'chapter' | 'bibliograph' | 'title' | 'appendix' | 'free';
    /** The schema being applied to the ProseMirror document (defaults to chapter) */
    schema?: 'title' | 'chapter' | 'free';
    /** The placement of the part in the document */
    placement?: 'body' | 'front' | 'back' | 'cover';
    /** The numbering counter style */
    numbering?: counterStyle;
    /** The semantic role of the part (e.g. methods) */
    role?: string;
    options?: any;
}

/** https://www.w3.org/TR/css-counter-styles-3/#predefined-counters */
export type counterStyle = undefined | 'none' | 'decimal' | 'lower-roman' | 'upper-roman' | 'lower-alpha' | 'upper-alpha' | string;

export const counterStyleValues: { [counterStyle: string]: (v: number) => string | number; } = {
    'decimal': (v: number) => v,
    'lower-alpha': (v: number) => 'a b c d e f g h i j k l m n o p q r s t u v w x y z'[(v - 1) * 2],
    'upper-alpha': (v) => 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z'[(v - 1) * 2],
    'upper-roman': (v) => romanize(v),
    'lower-roman': (v) => romanize(v).toLowerCase()
};

/** A resource that is referenced in the document. */
export interface DocumentSnapshotResource {
    /** Unique identifier (should be equal to figure ids if used in the document) */
    id: string;
    /** The resoruce type */
    type: 'image' | string;
    /** URL that can be used to fetch the resource */
    url: string;
    /** Optional mime type */
    mimeType?: string;
    /** Additional attributes */
    [key: string]: unknown;
}

export interface DocumentSnapshotImage extends DocumentSnapshotResource {
    type: 'image';
    dimensions: {
        height: number;
        width: number;
    };
}