import {
  AfterViewChecked,
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, InjectionToken, Injector, Input,
  OnDestroy,
  Optional, Output, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { schemas, SFNodeType } from '@sciflow/schema';
import { setBlockType } from 'prosemirror-commands';
import { exampleSetup } from 'prosemirror-example-setup';
import { Node } from 'prosemirror-model';
import { EditorState, NodeSelection, Plugin, PluginKey, TextSelection } from 'prosemirror-state';
import { EditorView, NodeView } from 'prosemirror-view';
import { registerInstance, removeInstance, updateDocumentState, updateState } from '../editor.actions';

import { Overlay, OverlayConfig, OverlayRef, PositionStrategy, ScrollDispatcher } from '@angular/cdk/overlay';
import { ComponentPortal, Portal } from '@angular/cdk/portal';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { updatePart } from 'document-outline';
import { InputRule, inputRules } from 'prosemirror-inputrules';
import { keymap } from 'prosemirror-keymap';
import { Selection } from 'prosemirror-state';
import { columnResizing, goToNextCell, tableEditing } from 'prosemirror-tables-ts';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, take, takeUntil, tap } from 'rxjs/operators';
import { FileService } from 'shared';
import { selectFootnoteActive, selectInstance } from '../editor.reducer';
import { EditorService } from '../editor.service';
import { CommandGroup, liftList, MenuService, sinkList, toggleList } from '../menu.service';
import { CiteComponent } from '../text-elements/cite/cite.component';
import { DropService } from '../text-elements/figure/drop.service';
import { FigureComponent } from '../text-elements/figure/figure.component';
import { HyperlinkDialogComponent } from '../text-elements/hyperlink-dialog/hyperlink-dialog.component';
import { MathEditorComponent } from '../text-elements/math/math-editor/math-editor.component';
import { MathService } from '../text-elements/math/math.service';
import { highlight, insertFigure, insertHyperlink, insertTable } from './commands';
import { createHoveringPlugin, matchNode } from './plugins/hovering';
import { fixProblems, integrityPlugin } from './plugins/integrity';
import { replacePlugin } from './plugins/replace';
import { statePlugin, statePluginKey } from './plugins/state';
import { StructureMenuComponent } from './structure-menu/structure-menu.component';
import { CodeBlockView } from './views/CodeBlockView';
import { FigureView } from './views/figure';
import { MathView } from './views/math.view';
import { XRefView } from './views/xref';
export const DOCUMENT_PORTAL_DATA = new InjectionToken<any>('DocumentPortalData');

export const PM_EDITOR_CONFIG = new InjectionToken<{}>('PM_EDITOR_CONFIG');

@Component({
  selector: 'sfo-editor',
  templateUrl: './pm-editor.component.html',
  styleUrls: ['./pm-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true,
  encapsulation: ViewEncapsulation.None
})
export class PMEditorComponent implements AfterViewInit, OnDestroy, AfterViewChecked {

  tex = '';
  numbered = false;

  private view: EditorView;

  observer: IntersectionObserver;

  isOpen = false;
  inActiveMainMenu = false;
  documentId: string;
  citeDialogueRef: MatDialogRef<CiteComponent>;
  documentId$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @Input('hideModals') hideModals;
  @Input('mode') mode: 'full' | null = null;
  @Input('schema') schema: 'inline' | 'chapter' | 'manuscript' = 'manuscript';
  @Input('editorId') editorId: { id: string; projectId: string };
  @Input('content') content;

  private plugins: Plugin[] = [];
  private commands: CommandGroup[] = [];
  private views: { [key: string]: NodeView } = {};

  @Output('change') change = new EventEmitter();

  @ViewChild('sfoEditorOverlayContainer') sfoEditorOverlayContainer: ElementRef;
  @ViewChild('editor') editorDiv;
  @ViewChild('editorWrapper') editorWrapper;
  @ViewChild('box') box;
  @ViewChild('bottomActions', { read: ViewContainerRef }) bottomActions: ViewContainerRef;

  menu$ = this.menuService.menu$;
  stop$ = new Subject();
  state$ = new Subject<{ state: any; cursorPos: any; selection?: any; }>();

  selectedPortal: Portal<any>;
  tableMenuVisible: boolean;

  private selectionOverlay: OverlayRef;
  /** The current structuring menu overlay */
  private structuringMenuOverlayRef: OverlayRef | null;
  private structMenu: ComponentPortal<StructureMenuComponent> | null;
  private positionStrategy: PositionStrategy;
  paramsSubscription: Subscription;

  saveJson: BlobPart;
  menuResult: any[];
  footnoteMenu: any[];

  constructor(
    private fileService: FileService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private menuService: MenuService,
    private mathService: MathService,
    private store: Store,
    private renderer: Renderer2,
    private element: ElementRef,
    private overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private dropService: DropService,
    private snackBar: MatSnackBar,
    private scrollDispatcher: ScrollDispatcher,
    private editorService: EditorService,
    private injector: Injector,
    private changeDetector: ChangeDetectorRef,
    private bottomSheet: MatBottomSheet,
    @Inject(PM_EDITOR_CONFIG) @Optional() private config: any
  ) {
    // start with initial client bounding rect ofthe surrounding scroll container
    let initialOffset = 0;
    const k = this.scrollDispatcher.scrollContainers.keys();
    for (const s of k) {
      if (s.getElementRef().nativeElement.getAttribute('id') === 'editor-drawer') {
        initialOffset = -s.getElementRef().nativeElement.getBoundingClientRect().top;
      }
    }

    if (this.config) {
      this.plugins = config.plugins || [];
      this.commands = config.commands || [];
      this.views = config.views || {};
      this.editorId = config.id;
      this.mode = config.mode || 'full';
    }

    this.iconRegistry.addSvgIcon('sf-subscript', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/sf-subscript.svg'));
    this.iconRegistry.addSvgIcon('sf-superscript', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/sf-superscript.svg'));
    this.iconRegistry.addSvgIcon('table-delete', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/round-delete_outline-24px.svg'));
    this.iconRegistry.addSvgIcon('table-new-column-right', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-column-new-right.svg'));
    this.iconRegistry.addSvgIcon('table-new-column-left', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-column-new-left.svg'));
    this.iconRegistry.addSvgIcon('table-new-row-below', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-row-new-below.svg'));
    this.iconRegistry.addSvgIcon('table-new-row-above', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-row-new-above.svg'));
    this.iconRegistry.addSvgIcon('table-text-align-right', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-text-align-right.svg'));
    this.iconRegistry.addSvgIcon('table-text-align-center', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-text-align-center.svg'));
    this.iconRegistry.addSvgIcon('table-text-align-justified', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-text-align-justified.svg'));
    this.iconRegistry.addSvgIcon('table-text-align-left', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-text-align-left.svg'));
    this.iconRegistry.addSvgIcon('table-column-delete', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-column-delete.svg'));
    this.iconRegistry.addSvgIcon('table-row-delete', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-row-delete.svg'));
    this.iconRegistry.addSvgIcon('table-merge-cells', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-merge-cells.svg'));
    this.iconRegistry.addSvgIcon('table-split-cell', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-split-cell.svg'));
    this.iconRegistry.addSvgIcon('table-column-heading', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-column-heading.svg'));
    this.iconRegistry.addSvgIcon('table-row-heading', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-row-heading.svg'));
    this.iconRegistry.addSvgIcon('figure-replace-figure-image', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/tables/sf-icon-replace-figure.svg'));
    this.iconRegistry.addSvgIcon('add_author', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/add_author.svg'));
    this.iconRegistry.addSvgIcon('footnote', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/footnote.svg'));
    this.iconRegistry.addSvgIcon('cite', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/cite.svg'));
    this.iconRegistry.addSvgIcon('blockquote', this.sanitizer.bypassSecurityTrustResourceUrl('assets/sf-icons/blockquote.svg'));

    // visible/disable table menu
    this.menu$.subscribe(res => {
      this.menuResult = res;
      const result = res.filter(command => command.id === 'table');
      this.tableMenuVisible = result && result[0].commands[0].active;
    });

    // footnote Menus
    this.menu$.subscribe(res => {
      let result: any = res.filter(command => command.id === 'format');
      const footnoteMenu: any[] = [];
      result = result[0].commands.forEach(element => {
        if (element.id === 'strong' || element.id === 'em' || element.id === 'sub' || element.id === 'sup' || element.id === 'undo' || element.id === 'redo') {
          footnoteMenu.push(element);
        }
      });
      this.footnoteMenu = footnoteMenu;
    });
  }

  /** Displays the structuring menu next to the text. */
  positionStructuringMenu(data: any) {
    if (data?.inFigure) { return; }

    // use something else than the body to host our element (so we get the z-index from the host rather than body)
    //this.overlay.setContainerElement(this.sfoEditorOverlayContainer.nativeElement);
    //console.log(this.sfoEditorOverlayContainer.nativeElement);

    const config = new OverlayConfig();
    config.positionStrategy = this.overlay.position()
      .flexibleConnectedTo(data.el)
      .withPositions([{
        overlayX: 'end',
        overlayY: 'top',
        originX: 'start', // use the top left corner
        originY: 'top', // of our origin element (e.g. the div the overlay is put into)
      }])
      .withDefaultOffsetX(-185);

    config.hasBackdrop = false;

    if (!this.structuringMenuOverlayRef) {
      this.structuringMenuOverlayRef = this.overlay.create({
        backdropClass: 'structuring-overlay',
        hasBackdrop: false,
        maxHeight: '1px',
        maxWidth: '1px'
      });
    }

    if (this.structuringMenuOverlayRef.hasAttached()) {
      this.structuringMenuOverlayRef.detach();
    }

    this.structuringMenuOverlayRef.updatePositionStrategy(config.positionStrategy);

    this.structMenu = new ComponentPortal(StructureMenuComponent, null, this.createInjector(data));
    this.structuringMenuOverlayRef.attach(this.structMenu);
  }

  /**
   * Update the editor state through a command.
   * Any modals that are required will be created as part of the command.
   * @param commandAction the command action
   * @param pos the text position
   */
  execCommand(commandAction: any, pos?: number): void {
    if (!commandAction.id) { throw new Error('Command id has to be provided'); }

    const lastSelectionFrom = this.view.state.selection?.from ?? 0;

    // get existing node to update attributes
    const state = this.view.state;

    let blockNode = state.doc.nodeAt((pos ?? lastSelectionFrom));
    if (!blockNode?.isBlock) {
      // try to find the parent
      let $pos = state.doc?.resolve((pos ?? lastSelectionFrom));
      let same = $pos.sharedDepth((pos ?? lastSelectionFrom));
      if (same != 0) {
        pos = $pos.before(same);
        blockNode = state.doc.nodeAt(pos);
      }
    }

    let dialogRef, runFn;
    const schema = this.view.state.schema;
    switch (commandAction.id) {
      // sets the type attribute on the chapter (e.g. level 1 heading)
      case 'set-part-type':
        {
          const { type } = commandAction.payload;
          // if (blockNode?.type.name !== SFNodeType.heading && blockNode?.type.name != SFNodeType.paragraph) {
          if (blockNode?.type.name !== SFNodeType.heading) {
            throw new Error('Can only update heading attributes (was ' + blockNode?.type.name + ')');
          }
          runFn = (state, dispatch) => {
            const tr = state.tr;
            if (dispatch) {
              tr.setNodeAttribute(pos, 'type', type);
              tr.setSelection(TextSelection.create(tr.doc, (pos ?? lastSelectionFrom) + 1));
              dispatch(tr);
            }
          }
        }
        break;
      case 'set-heading-level':
        {
          const { level } = commandAction.payload;
          // if (blockNode?.type.name !== SFNodeType.heading && blockNode?.type.name != SFNodeType.paragraph) {
          if (blockNode?.type.name !== SFNodeType.heading) {
            throw new Error('Can only update heading attributes (was ' + blockNode?.type.name + ')');
          }
          runFn = (state, dispatch) => {
            const tr = state.tr;
            if (dispatch) {
              tr.setNodeAttribute(pos, 'level', level);
              tr.setSelection(TextSelection.create(tr.doc, (pos ?? lastSelectionFrom) + 1));
              dispatch(tr);
            }
          }
        }
        break;
      // sets the type of node (e.g. heading, paragraph)
      case 'set-node-type':
        {
          const { type } = commandAction.payload;
          runFn = setBlockType(schema.nodes[type], {});
        }
        break;
      // sets the numbering attribute on the level 1 heading
      case 'set-part-numbering':
        const { numbering } = commandAction.payload;
        if (blockNode?.type.name !== SFNodeType.heading) {
          throw new Error('Can only update heading attributes (was ' + blockNode?.type.name + ')');
        }
        runFn = (state, dispatch) => {
          const tr = state.tr;
          if (dispatch) {
            tr.setNodeAttribute(pos, 'numbering', numbering);
            tr.setSelection(TextSelection.create(tr.doc, (pos ?? lastSelectionFrom) + 1));
            dispatch(tr);
          }
        }
        break;
      // sets the role attribute on the level 1 heading
      case 'set-part-role':
        const { role } = commandAction.payload;
        runFn = setBlockType(schema.nodes.heading, {});
        if (blockNode?.type.name !== SFNodeType.heading) {
          throw new Error('Can only update heading attributes (was ' + blockNode?.type.name + ')');
        }
        runFn = (state, dispatch) => {
          const tr = state.tr;
          if (dispatch) {
            tr.setNodeAttribute(pos, 'role', role);
            tr.setSelection(TextSelection.create(tr.doc, (pos ?? lastSelectionFrom) + 1));
            dispatch(tr);
          }
        }
        break;
      case 'delete':
        throw new Error('delete not implemented');
        /*         {
                  let pos;
                  // FIXME FE
                  // @ts-ignore
                  if (this.view.state.selection.$head.path[2] !== 0) {
                    // @ts-ignore
                    pos = this.view.state.selection.$head.path[2];
                  } else { pos = this.view.state.selection.$anchor.pos; }
                  const tr = this.view.state.tr;
                  this.view.dispatch(tr
                    .setSelection(NodeSelection.create(tr.doc, pos))
                    .deleteSelection()
                  );
                } */
        return;
      case 'hyperlink':
        const selectedNode = this.view.state.doc.cut(this.view.state.selection.from, this.view.state.selection.to);
        const text = selectedNode && selectedNode.textContent || '';
        dialogRef = this.dialog.open(HyperlinkDialogComponent, {
          data: { text }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!result.attrs) { return; }
          return insertHyperlink(result.attrs, result.text)(this.view.state, this.view.dispatch);
        });
        return;
      case 'math':
        dialogRef = this.dialog.open(MathEditorComponent, this.config);

        dialogRef.afterClosed().subscribe(result => {
          const tr = this.view.state.tr;
          this.view.dispatch(tr
            .setSelection(TextSelection.create(tr.doc, this.view.state.selection.$anchor.pos))
            .scrollIntoView()
          );

          if (!result) { return; }
          const { tex, numbered } = result;
          this.tex = tex;
          this.numbered = numbered;
          this.emitChange();
        });
        return;
      case 'table':
        const figuredialogRef = this.dialog.open(FigureComponent);

        figuredialogRef.afterClosed().subscribe(result => {
          if (result.id === 'create-table') {
            const createCommand = insertTable({ cols: result.payload.cols, rows: result.payload.rows, hasHeaderRow: true });
            createCommand(this.view.state, this.view.dispatch);
          }
          if (result.id === 'upload-image') {
            const createCommand = insertFigure('table');
            createCommand(this.view.state, this.view.dispatch);
          }
        });
        return;
      case 'bullet_list':
      case 'ordered_list':
        liftList(this.view.state, this.view.dispatch);
        toggleList(this.view.state, this.view.dispatch, commandAction.id);
        return;
      default:
        if (commandAction.run === undefined) {
          throw new Error('command action has no runner');
          //const result = this.menuResult.filter(res => res.id === 'structure');
          //commandAction = result[0].commands.find(citation => citation.id === commandAction.id);
        }
        runFn = commandAction.run
    }

    try {
      const result = runFn(state, this.view.dispatch);
      console.warn('command: ', commandAction, ' > ', result, this.view.state.doc.nodeAt((pos ?? lastSelectionFrom))?.attrs);
      this.view.focus();
    } catch (e) {
      console.error('Could not run command', commandAction, e);
    }
  }

  // Emits a change based on the latest TeX provided by the editor.
  async emitChange(): Promise<void> {
    if (this.tex.length === 0) { return; }
    const tr = this.view.state.tr.replaceWith(this.view.state.selection.from,
      this.view.state.selection.to,
      this.view.state.schema.node('math', {
        id: this.view.state.doc.attrs.id,
        tex: this.tex,
        style: this.numbered ? 'block' : 'inline'
      }, [
        this.view.state.schema.text(this.tex)
      ]));

    this.view.dispatch(tr);
  }

  /**
   * Emits the current state of the document to the rest of the app.
   */
  emitExternalState({ state }): void {
    const pluginState = statePluginKey.getState(state);
    if (pluginState) {
      const elements = pluginState.elements;
      let selection;
      let selectedAnchors: { anchor: any; node: any; }[] = [];
      if (state.selection instanceof NodeSelection) {
        selection = {
          id: state.selection?.node?.attrs?.id,
          type: state.selection?.node.type.name
        };
      } else {
        const { from, to, empty } = state.selection;
        state.doc.nodesBetween(from, to, (node, pos) => {
          if (node.marks) {
            const nodeFrom = pos;
            const nodeTo = pos + node.nodeSize;
            const anchor = node.marks.find(mark => mark.type.name === 'anchor');
            if (anchor) {
              if (from >= nodeFrom && to <= nodeTo) {
                selectedAnchors = [...selectedAnchors, { anchor, node }];
              }
            }
          }
        });

        selection = {
          id: state.selection.$anchor?.parent?.attrs?.id,
          type: state.selection.$anchor?.parent?.type.name,
          selectedAnchors
        };
      }

      this.store.dispatch(updateState({
        id: this.editorId.id,
        projectId: this.editorId.projectId,
        elements,
        selection,
        state: {
          doc: state.doc.toJSON()
        }
      }));
      this.store.dispatch(updatePart({ part: elements }));
    }
  }

  fullScreen() {
    const elem = this.element.nativeElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    }
  }

  // FIXME #48 the editor shuld not know in what context it is added
  // this should be part of the footnote component
  updateFootnote() {
    this.change.emit(this.view.state.doc);
  }

  serialise(schema, selectedContent) {
    let node = schema.nodes.doc.create({});
    // FIXME remove workaround for schema conversion
    const serialized = selectedContent.content.firstChild.toJSON();
    const parsed = Node.fromJSON(schema, serialized);
    node = schema.nodes.doc.create({}, [parsed]);
    // node.check();
    return node;
  }

  /** Transforms from the inline schema to the document schema */
  transformToDocumentSchema(footnoteInlineDoc: Node): Node {
    let node: Node | null = null;
    if (!node) {
      node = this.view.state.doc.cut(this.view.state.selection.from, this.view.state.selection.to);
    }
    const serialized = footnoteInlineDoc.toJSON();
    const targetNode = Node.fromJSON(this.view.state.schema, { ...serialized, type: 'footnote', attrs: node.attrs });
    return targetNode;
  }

  highlight(searchTerm: string) {
    highlight(searchTerm)(this.view.state, this.view.dispatch);
  }

  arrowHandler(dir): any {
    return (state, dispatch, view) => {
      if (state.selection.empty && view.endOfTextblock(dir)) {
        const side = dir === 'left' || dir === 'up' ? -1 : 1;
        const $head = state.selection.$head;
        const resolvedPos = state.doc.resolve(side > 0 ? $head.after() : $head.before());
        const nextPos = Selection.near(resolvedPos, side);

        if (nextPos.$head && nextPos.$head.parent.type.name === 'code') {
          dispatch(state.tr.setSelection(nextPos));
          return true;
        }
      }
      return false;
    };
  }

  // to avoid error "ExpressionChangedAfterItHasBeenCheckedError" while adding footnote
  // Angular runs change detection and when it finds that some values which has been passed to the child component have been changed, throws "ExpressionChangedAfterItHasBeenCheckedError"
  ngAfterViewChecked(): void { this.changeDetector.detectChanges(); }

  async ngAfterViewInit(): Promise<void> {

    this.store.select(selectFootnoteActive).pipe(takeUntil(this.stop$)).subscribe(async (footnoteActive) => {
      if (footnoteActive) { this.inActiveMainMenu = true; }
      else { this.inActiveMainMenu = false; }
    });

    let editor;
    if (!this.content) {
      editor = await this.store.select(selectInstance(this.editorId.id)).pipe(filter(e => e !== undefined), take(1)).toPromise();
      console.log('initializing pm editor', editor);
    }

    this.observer = new IntersectionObserver((entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          console.log(entry);
        }
        (entry.target as any).notify(entry.isIntersecting);
      }
    }, {
      root: this.editorDiv.nativeElement,
      rootMargin: '0px',
      threshold: 1.0
    });

    if (!editor) {
      console.log('Initializing empty editor');
      this.store.dispatch(registerInstance({
        id: this.editorId.id,
        key: '',
        projectId: this.editorId.projectId,
        version: '',
        state: null,
        lastModified: undefined
      }));
    }

    const schema = schemas[this.schema] || schemas.manuscript;

    let doc;
    if (editor?.state?.doc) {
      doc = Node.fromJSON(schema, editor?.state?.doc);
      // doc.check();
    } else if (this.content) {
      doc = this.content;
      // doc.check();
    }

    this.menuService.schema = schema;

    let tables = [] as any;
    if (schema.nodes.table) {
      tables = [columnResizing({}),
      tableEditing(),
      keymap({
        'Tab': goToNextCell(1),
        'Shift-Tab': goToNextCell(-1)
      })];
    }

    let listIndent = [] as any;
    if (schema.nodes.bullet_list || schema.nodes.ordered_list) {
      listIndent = ([
        keymap({ 'Shift-Tab': liftList }),
        keymap({ Tab: sinkList })
      ]);
    }

    const arrowHandlers = keymap({
      ArrowLeft: this.arrowHandler('left'),
      ArrowRight: this.arrowHandler('right'),
      ArrowUp: this.arrowHandler('up'),
      ArrowDown: this.arrowHandler('down')
    });

    const isMath = new RegExp(/\$\$(.*)\$\$/);
    const mathType = schema.nodes['math'];
    const mathInputRule = new InputRule(isMath, (state, match, start, end) => {
      const result = this.mathService.renderEquation(match[1]);
      const text = (result.errors?.length > 0) ? (' ' + result.errors.map(({ message }) => message).join()) : result.node;
      return state.tr.replaceWith(start, end, mathType.create({ tex: match[1], style: 'inline' }, [schema.text(text)]));
    });

    const { plugin: hoveringPlugin, events: hoverEvents$ } = createHoveringPlugin();

    const state = EditorState.create({
      doc,
      schema,
      plugins: [
        ...exampleSetup({ schema, menuBar: false }).concat(arrowHandlers),
        inputRules({ rules: [mathInputRule] }),
        integrityPlugin,
        replacePlugin,
        // ideally this should go last
        ...this.plugins,
        statePlugin,
        ...tables,
        ...listIndent,
        hoveringPlugin,
        new Plugin({
          key: new PluginKey('style'),
          props: {
            attributes: { class: 'ProseMirror-SciFlow-style', spellcheck: 'false', 'data-gramm': 'false' }
          }
        })
      ]
    });

    const nodeViews = {
      figure: (node, view, getPos, decorations, innerDecorations) => new FigureView(node, view, { renderer: this.renderer, store: this.store, observer: this.observer, dropService: this.dropService, projectId: this.editorId.projectId }, getPos, this.documentId, this.snackBar, this.fileService),
      link: (node, view, getPos, decorations, innerDecorations) => new XRefView(node, view, { renderer: this.renderer, store: this.store, observer: this.observer }),
      // footnote: (node, nodeView, getPos, decorations) => new FootnoteView(node, nodeView, this.bottomSheet, getPos, this.injector, this),
      code_block: (node, nodeView, getPos, decorations) => new CodeBlockView(node, nodeView, getPos),
      math: (node, nodeView, getPos, decorations) => new MathView(node, nodeView, getPos, this.injector, this.view),
      ...this.views
    };

    const dispatchTransaction = (transaction) => {
      const { state: transactionState } = this.view.state.applyTransaction(transaction);
      this.view.updateState(transactionState);
      this.editorService.changeView(this.view);

      // updating document state when user make some changes in the document
      if (transaction.docChanged) {
        this.store.dispatch(updateDocumentState({ dirty: true }));
      }

      this.state$.next({ state: transactionState, cursorPos: this.view.coordsAtPos(transactionState.selection.$anchor.pos), selection: transactionState.selection });
    };

    this.view = new EditorView(this.editorDiv.nativeElement, {
      dispatchTransaction,
      handleDOMEvents: {
        'blur': (view, event) => {
          if (this.structuringMenuOverlayRef?.hasAttached()) {
            if (event.relatedTarget instanceof HTMLElement) {
              if (!event.relatedTarget.classList.contains('mat-mdc-menu-trigger')) {
                // do not close for menus but anything else
                this.structuringMenuOverlayRef.detach();
              }
            }
          }
          return false;
        }
      }
      ,
      nodeViews,
      state
    });

    this.editorDiv.nativeElement.click();
    this.view?.focus();

    this.config?.offset$.subscribe(_ => {
      if (this.structuringMenuOverlayRef?.hasAttached()) {
        this.structuringMenuOverlayRef.dispose();
        this.structuringMenuOverlayRef = null;
      }
    });

    hoverEvents$.pipe(debounceTime(250)).subscribe(({ type, id, pos, coords, node, parent, inFigure }) => {
      const el: HTMLElement = this.element.nativeElement;
      const { top: editorOffsetY } = el.getBoundingClientRect();

      if (parent?.type?.name === SFNodeType.figure) {
        return;
      }

      const n = document.getElementById(node.attrs?.id);
      this.positionStructuringMenu({
        type: node.type.name,
        id: node.attrs?.id,
        pos,
        el: n,
        node: node,
        parent: parent,
        inFigure,
        exec: (command) => this.execCommand(command, pos)
      });
    });

    // update rest of the app
    this.state$.pipe(takeUntil(this.stop$), debounceTime(100), tap(({ state }) => {
      this.menuService.update(state, this.schema, this.commands);
      const cursorPos = this.view.coordsAtPos(state.selection.from);
      const el: HTMLElement = this.element.nativeElement;
      // get the y position at which the editor begins from the top of the viewport

      const n = document.getElementById(state.selection.$anchor?.parent?.attrs?.id);
      const inFigure = state.selection.$anchor.node(1)?.type.name === SFNodeType.figure;

      const { top: editorOffsetY } = el.getBoundingClientRect();
      const pos = state.selection.from;
      const match = matchNode(this.view, pos);
      if (match?.node) {

        this.positionStructuringMenu({
          type: match?.node.type.name,
          id: match?.node.attrs?.id,
          pos,
          el: n,
          node: match?.node,
          inFigure,
          exec: (command) => this.execCommand(command, pos)
        });
      }
    }), debounceTime(800)).subscribe((update: any) => this.emitExternalState(update));

    // run the document initially to create ids for all elements
    const fixTr = fixProblems(this.view.state);
    if (fixTr) {
      dispatchTransaction(fixTr);
    } else {
      dispatchTransaction(this.view.state.tr);
    }
  }

  /**
   * Creates a custom injector so we can had off data to the overlay portal and listen to results.
   */
  private createInjector(data: any): Injector {
    return Injector.create({ providers: [{ provide: DOCUMENT_PORTAL_DATA, useValue: data }], parent: this.injector });
  }

  ngOnDestroy(): void {
    if (this.structuringMenuOverlayRef?.hasAttached()) {
      this.structuringMenuOverlayRef.dispose();
    }
    this.store.dispatch(removeInstance({ id: this.editorId.id }));
    this.stop$.next();
  }

}
