import { Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { NodeSelection } from 'prosemirror-state';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DropService } from '../../text-elements/figure/drop.service';
import { insertCaption } from '../commands';
import { FileService } from 'shared';
import { selectActiveInstance, selectFiles } from '../../editor.reducer';
import { debounceTime, take } from 'rxjs/operators';
import { replaceFile } from '../../editor.actions';

export class FigureView {
    captionData = {
        hasNotes: '',
        hasCaption: ''
    };

    dom;
    contentDOM: HTMLDivElement;
    figureElement;

    private stop$ = new Subject();

    constructor(private node, private view, private options: {
        renderer: Renderer2; store: Store; observer: IntersectionObserver; dropService: DropService;
        projectId: string;
    }, private getPos, private documentId, private snackBar: MatSnackBar, private fileService: FileService) {
        this.dom = document.createElement('div');
        this.dom.classList.add('figure-wrapper');
        this.dom.setAttribute('data-id', this.node.attrs.id);
        this.dom.setAttribute('data-type', this.node.attrs.type);
        this.dom.setAttribute('id', this.node.attrs.id);
        this.dom.setAttribute('data-dpi', this.node.attrs?.metaData?.density);
        this.dom.contentEditable = true;

        this.contentDOM = document.createElement('div');

        this.figureElement = document.createElement('sf-figure-menu-element');
        this.figureElement.contentEditable = false;
        this.figureElement.node = node;
        this.figureElement.setAttribute('document-id', this.documentId);

        this.dom.appendChild(this.figureElement);
        this.dom.appendChild(this.contentDOM);

        options.store.select(selectFiles).pipe(take(1)).toPromise().then((files) => {
            const file = files?.find(f => f.id === node.attrs.id);
            this.figureElement.file = file;
        });

        this.dom.addEventListener('click', (event) => {
            if (!(event.target instanceof HTMLImageElement)) { return; }
            this.view.dispatch(this.view.state.tr.setSelection(NodeSelection.create(this.view.state.doc, this.getPos())))
            event.preventDefault();
        });

        const handleImageFile = async (files) => {

            if (!files || files.length === 0) { return; }

            if (!isFileTypeAllowed(files, this.snackBar)) {
                this.figureElement.isUploading = false;
                return;
            }

            const tr = this.view.state.tr;
            const node = tr.doc.nodeAt(this.getPos());

            const instance = await options.store.select(selectActiveInstance).pipe(take(1)).toPromise();
            const previousFile = instance.state?.files?.find(f => f.id === node.attrs.id);
            this.figureElement.isUploading = true;
            const file = files.item(0);
            const result = await this.fileService.uploadFile(this.options.projectId, file);
            options.store.dispatch(replaceFile({ id: instance.id, projectId: result.projectId, file: { id: node.attrs.id, name: file.name, url: `/export/asset/${result.key}` } }));

            await this.options.dropService.processFiles(files).subscribe(async (file) => {
                const tr = this.view.state.tr;
                tr.setNodeMarkup(this.getPos(), null, { ...this.node.attrs, src: file.thumbnail }, this.node.marks);
                this.view.dispatch(tr);
                this.figureElement.isUploading = false;
                this.snackBar.open('Uploaded figure ' + file.name, 'Close', { duration: 5000 });
            }, (error) => {
                console.error(error);
                this.figureElement.isUploading = false;
                this.snackBar.open('Could not process file ' + file.name, 'Close', { duration: 5000 });
            });
            return;
        }

        this.figureElement.addEventListener('commands', async ({ detail }) => {
            switch (detail.id) {
                case 'delete':
                    {
                        const tr = this.view.state.tr;
                        this.view.dispatch(tr
                            .setSelection(NodeSelection.create(tr.doc, this.getPos()))
                            .deleteSelection()
                        );
                    }
                    break;
                case 'add-caption':
                    {
                        insertCaption(this.node, this.getPos())(this.view.state, this.view.dispatch);

                        this.contentDOM.setAttribute('has-caption', 'true');
                        this.contentDOM.setAttribute('has-notes', 'false');

                        this.figureElement.node = node;
                        this.figureElement.captionData = this.captionData;
                    }
                    break;
                case 'rotate':
                    {
                        const tr = this.view.state.tr;
                        tr.setNodeMarkup(this.getPos(), null, { ...this.node.attrs, orientation: this.node.attrs?.orientation === 'landscape' ? 'portrait' : 'landscape' }, this.node.marks);
                        this.view.dispatch(tr);
                    }
                    break;
                case 'upload-image':
                    {
                        const { payload } = detail;
                        await handleImageFile(payload.files);
                    }
                    break;
            }
        });

        this.checkNode(node);
    }

    private checkNode(node) {
        let hasCaption = '';
        let hasNotes = '';
        const figcaption = this.node.content.content.find(node => node.type.name === 'caption');
        if (figcaption) {
            const paragraphs = figcaption.content.content.filter(node => node.type.name === 'paragraph');
            hasCaption = paragraphs.length > 0 as any;
            hasNotes = paragraphs.length > 1 as any;
        }

        this.captionData = {
            hasNotes,
            hasCaption
        };

        this.contentDOM.setAttribute('has-caption', hasCaption);
        this.contentDOM.setAttribute('has-notes', hasNotes);

        this.dom.node = node;
        this.dom.captionData = this.captionData;
    }

    update(node, decorations) {
        if (!node.sameMarkup(this.node)) { return false; }
        if (node.type !== this.node.type) { return false; }
        this.node = node;
        return true;
    }

    selectNode() {
        this.dom.classList.add('selected');
    }

    deselectNode() {
        this.dom.classList.remove('selected');
    }

    ignoreMutation(mutation) {
        return true;
    }

    destroy() {
        this.stop$.next();
    }
}

export const isFileTypeAllowed = (files, snackBar, windowRef?) => {
    if (!files || files.length === 0) { return false; }
    for (let file of files) {
        switch (file.type) {
            case 'image/png':
            case 'image/svg+xml':
            case 'image/gif':
            case 'image/tiff':
            case 'image/jpeg':
                break;
            case 'image/x-eps':
            case 'application/pdf':
                snackBar.open('Could not process file', 'Close', { duration: 5000 });
                return false;
            default:
                snackBar.open('The file type you provided is currently not supported.', null, { duration: 4000 });
                return false;
        }
    }

    return true;
}
