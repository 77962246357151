import { NgModule } from '@angular/core';
import { ExportRoutingModule } from './export-routing.module';
import { ExportComponent } from './export/export.component';
import { exportFeatureKey, exportMetadataReducer } from './store/export.reducer';
import { StoreModule } from '@ngrx/store';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    ExportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ExportRoutingModule,
    MatInputModule,
    MatProgressBarModule,
    MatMenuModule,
    MatOptionModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    MatIconModule,
    StoreModule.forFeature(exportFeatureKey, exportMetadataReducer)
  ]
})
export class ExportModule { }
