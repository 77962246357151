import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { updateDocumentState } from 'editor';
import { Subject, timer } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { createId } from '@sciflow/schema';
import { checkSelectedAuthor, selectDocumentTitle, selectDocumentModified, selectIsDirty } from '../app/app-state.reducer';

import { selectIsDocumentLoaded, selectOpenFile } from './file.reducer';
import { ProjectService } from '../editor/services/project.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy {
  @ViewChild('copyToClipboardInput') copyToClipboardInput: ElementRef;

  @HostListener('keydown', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    if ((e.ctrlKey || e.metaKey) && e.key === 's') {
      e.stopPropagation();
      e.preventDefault();
      this.save();
    }
  }

  stop$ = new Subject();

  isDark = false;
  dcaExpanded$ = this.store.select(checkSelectedAuthor);
  documentTitle$ = this.store.select(selectDocumentTitle);
  lastModified$ = this.store.select(selectDocumentModified);
  isDocumentLoaded$ = this.store.select(selectIsDocumentLoaded);

  openFile$ = this.store.select(selectOpenFile);

  instanceData = JSON.parse(this.document.getElementById('instance-data').innerHTML);

  myControl = new UntypedFormControl();

  themes: any[] = [
    {
      full: 'Use device theme',
      short: 'ST'
    },
    {
      full: 'Dark theme',
      short: 'DT'
    },
    {
      full: 'Light theme',
      short: 'LT'
    }
  ];
  selectedTheme = 'ST';

  selectedThemeControl = new UntypedFormControl(this.selectedTheme);

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
    private router: Router,
    private snackBar: MatSnackBar,
    private projectService: ProjectService,
    @Inject(DOCUMENT) private document: any
  ) {
    console.log(this.instanceData);
    this.checkdefaultAppmode();
    this.addwatchthemePrefers();
    this.router.errorHandler = (error: any) => {
      this.router.navigate(['/404']); // redirect to page-not-found
    };
  }

  /**
   * Copies the current url to the clipboard.
   */
  copyToClipboard(): void {
    // this.copyToClipboardInput.nativeElement.value = window.location.href;
    // this.copyToClipboardInput.nativeElement.select();

    // document.execCommand('copy');

    const textToCopy = window.location.href;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        this.snackBar.open(`We have copied the link to this document to your clipboard`, undefined, { duration: 5000 });
      })
      .catch((error) => {
        this.snackBar.open(`Copy failed! ${error}`, undefined, { duration: 5000 });
      })
  }

  checkdefaultAppmode(): void {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      if (this.selectedTheme === 'DT') { this.isDark = true; }
      if (this.selectedTheme === 'LT') { this.isDark = false; }
      else { this.isDark = true; }
    }
  }

  addwatchthemePrefers(): void {
    // To watch for changes:
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      const newColorScheme = e.matches ? 'dark' : 'light';
      if (this.selectedTheme === 'ST') {
        if (newColorScheme === 'dark') { this.isDark = true; }
        else { this.isDark = false; }
      }
      this.cd.markForCheck();
    });
  }

  selectTheme(value): void {
    this.selectedTheme = value;
    if (value === 'DT') { this.isDark = true; }
    if (value === 'LT') { this.isDark = false; }
    if (value === 'ST') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) { this.isDark = true; }
      else { this.isDark = false; }
    }
  }

  ngOnDestroy(): void {
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', e => {
      this.cd.detach();
    });
    this.stop$.next();
  }

  async save() {

    // wait a few ms for events to settle that happened before save
    const isDirty = await this.store.select(selectIsDirty).pipe(debounceTime(250), take(1)).toPromise();

    if (isDirty) {
      this.snackBar.open('Saving ...');
      const persistedStore: any = await this.projectService.persistStore();

      if (!persistedStore) {
        console.error(persistedStore);
        this.snackBar.open('Could not process file', 'Close', { duration: 5000 });
      } else {
        console.log('Updated successfully', persistedStore.result);
        this.snackBar.open('Saved successfully', 'Close', { duration: 5000 });
        this.store.dispatch(updateDocumentState({ dirty: false }));
        const url = `/write/${persistedStore.result.key}/${persistedStore.result.version?.VersionId || 'unversioned-' + createId()}/(context:export/${persistedStore.data?.manuscript?.template || 'generic-journal'})`;
        this.router.navigateByUrl(url);
        return;
      }
    }

    const file: any = await this.openFile$.pipe(take(1)).toPromise();
    const url = `/write/${file.projectId}/${file.id}.json/${file.version}/(context:export/generic-journal)`;
    this.router.navigateByUrl(url);
  }

}
