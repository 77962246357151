<form [formGroup]="authorForm" *ngIf="author$ | async; let author;">
    <div class="flex items-center flex-wrap justify-between mx2">
        <div class="flex items-center" *ngIf="isAuthor">
            <sf-user-card class="mxn1" [user]="author">
            </sf-user-card>
            <span *ngIf="!author.name && !author.email && !author.firstName && !author.lastName">Unnamed author</span>
            <button mat-icon-button [matMenuTriggerFor]="authorOptions">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #authorOptions="matMenu">
                <button mat-menu-item (click)="deleteAuthor(author.authorId)">
                    <mat-icon>delete_outline</mat-icon>
                    <span>Remove author</span>
                </button>
                <button mat-menu-item (click)="hideInPublication(author.authorId, false)"
                    *ngIf="author.hideInPublication">
                    <mat-icon>visibility</mat-icon>
                    <span>Show in publication</span>
                </button>
                <button mat-menu-item (click)="hideInPublication(author.authorId, true)"
                    *ngIf="!author.hideInPublication">
                    <mat-icon>visibility_off</mat-icon>
                    <span>Hide from publication</span>
                </button>
<!--                 <button mat-menu-item (click)="makeGroupContribution(author.authorId)" *ngIf="author.type !== 'group'">
                    <mat-icon>group_work</mat-icon>
                    <span>Turn into group contribution</span>
                </button> -->
                <button mat-menu-item *ngIf="author.currentUser" (click)="clearAuthorData(author.authorId)">
                    <mat-icon>sync_alt</mat-icon>
                    <span>Use SciFlow profile data</span>
                </button>
            </mat-menu>
        </div>
        <div class="flex-grow"></div>
        <div class="md-ml4 flex items-center flex-wrap" *ngIf="author.editable">
            <mat-checkbox color="primary" [ngStyle]="{'display': isAuthor && author.currentUser ? 'block' : 'none'}"
                #updateProfileTop [checked]="author.currentUser">Update profile</mat-checkbox>
            <div class="px1">
                <button mat-button class="uppercase" (click)="close()">Discard Changes</button>
            </div>
            <button mat-button color="primary" class="uppercase"
                (click)="save({ updateProfile: updateProfileTop?.checked })"
                [disabled]="authorForm.pristine && authorForm.valid">save</button>
        </div>
    </div>
    <div class="author-edit p2 m2">
        <div class="mb3" *ngIf="isAuthor">
            <div class="flex justify-between flex-wrap">
                <div>
                    <mat-form-field appearance="outline" *ngIf="editUser && (author.allowedRoles!.length > 0)">
                        <mat-label>Author roles</mat-label>
                        <mat-select formControlName="roles" multiple>
                            <mat-option value="Owner" *ngIf="author.allowedRoles?.includes('Owner')">Owner</mat-option>
                            <mat-option value="Author" *ngIf="author.allowedRoles?.includes('Author')">Author</mat-option>
                            <mat-option value="CommentOnly" *ngIf="author.allowedRoles?.includes('CommentOnly')">Comment Only
                            </mat-option>
                            <!-- <mat-option value="read-only" *ngIf="author.allowedRoles.contains('ReadOnly')">Read Only</mat-option> -->
                            <mat-option value="Editor" *ngIf="author.allowedRoles?.includes('Editor')">Editor</mat-option>
                        </mat-select>
                        <mat-hint>Access rights</mat-hint>
                    </mat-form-field>
                </div>

                <div class="flex flex-column">
                    <mat-checkbox color="primary" class="col-12 md-col-4 px2" formControlName="correspondingAuthor">
                        Corresponding author</mat-checkbox>
                    <mat-checkbox color="primary" class="col-12 md-col-4 px2" formControlName="equalContribution">Equal
                        contribution</mat-checkbox>
                    <mat-checkbox color="primary" class="col-12 md-col-4 px2" formControlName="deceased">Deceased
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <h3>Author information</h3>
        <p *ngIf="(notAUser$ | async) && !author.invitedAt" class="my-auto p2">This author does not have an associated
            user account. They will
            still be added to the document. To add authors with write access, <mat-icon svgIcon="add_author"></mat-icon>
            invite them to join with their account.</p>
        <p *ngIf="author.invitedAt" class="my-auto p2">The user was invited to join the document but has not accepted,
            yet.</p>
        <div class="flex items-center flex-wrap mxn2">
            <div class="col-8 md-col-10 px2">
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>ORCID</mat-label>
                    <!-- pattern="[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}" -->
                    <input matInput formControlName="orcid" placeholder="0000-0000-0000-0000" #orcidInput
                        cdkFocusInitial>
                    <mat-hint>Insert the 16-Digit ORCID to fetch meta data and affiliations</mat-hint>
                    
                </mat-form-field>
            </div>
            <div class="col-4 md-col-2">
                <div class="mb2 flex justify-between items-center">
                    <div class="mx2" *ngIf="isLoading$| async">
                        <mat-spinner diameter="24"></mat-spinner>
                    </div>
                    <button *ngIf="!(isLoading$ | async)" matTooltip="Read latest data from orcid.org" mat-button
                        [disabled]="!authorForm.controls.orcid.valid || !authorForm.controls.orcid.value"
                        (click)="getOrcid()">Request update</button>
                </div>
            </div>
        </div>

        <div class="flex items-center flex-wrap mxn2">
            <mat-form-field appearance="outline" class="col-12 md-col-2 px2" *ngIf="isAuthor && (notAUser$ | async)">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                    <mat-option value="group">Group / Organization</mat-option>
                    <mat-option value="author">Person</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="type.value === 'group'" class="col-12 md-col-10 px2">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="type.value !== 'group'" class="col-12 md-col-5 px2">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName">
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="type.value !== 'group'" class="col-12 md-col-5 px2">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName">
            </mat-form-field>
        </div>

        <div class="flex items-center flex-wrap mxn2">
            <mat-form-field appearance="outline" class="col-12 md-col-6 px2" *ngIf="!(notAUser$ | async)">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                <mat-hint>
                    <span>Emails can be set through the SciFlow <a href="/me">author profile actions</a></span>
                </mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 md-col-6 px2">
                <mat-label>Twitter</mat-label>
                <input matInput formControlName="twitter">
            </mat-form-field>
        </div>

        <div formArrayName="positions" class="pt2 mt2">
            <div class="flex items-center justify-between">
                <h3>Affiliations</h3>
                <button mat-button color="primary" class="uppercase" (click)="openAffiliationDialog()">add
                    affiliation</button>
            </div>
            <div *ngIf="positions.controls?.length === 0">
                <mat-hint>Add an affiliation to complete the author information.</mat-hint>
            </div>
            <div cdkDropList (cdkDropListDropped)="drop($event)">
                <div class="bc-primary-default flex items-center justify-between p2 mat-elevation-z2 mb1"
                    *ngFor="let affiliation of positions.controls;let j=index" [formGroupName]="j" cdkDrag
                    [cdkDragDisabled]="(positions.controls!.length < 2)">
                    <div>
                        <p class="h4 m0">{{affiliation.value.title}} @ {{affiliation.value.institution}}</p>
                        <div class="flex items-center">
                            <small>{{affiliation.value.department}}</small>
                            <mat-icon class="ml1" aria-hidden="false" aria-label="Place">place</mat-icon>
                            <small>{{affiliation.value.city}}, {{affiliation.value.country}}</small>
                        </div>
                    </div>

                    <div class="flex items-center" *ngIf="author.editable">
                        <button mat-icon-button aria-label="More actions" matTooltip="Edit affiliation"
                            (click)="openAffiliationDialog(affiliation)">
                            <mat-icon>create</mat-icon>
                        </button>
                        <button mat-icon-button class="mr1" matTooltip="Remove affiliation" aria-label="Delete"
                            (click)="deleteAffiliation(affiliation)">
                            <mat-icon>delete</mat-icon>
                        </button>

                        <button mat-icon-button class="mr1 drag-handle" matTooltip="Re-order affiliation"
                            aria-label="Drag" cdkDragHandle *ngIf="positions.controls!.length > 1">
                            <mat-icon>drag_indicator</mat-icon>
                        </button>

                        <div class="drag-placeholder" *cdkDragPlaceholder></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="my3" formArrayName="funders">
            <div class="flex items-center justify-between">
                <h3>Funders</h3>
                <button mat-button color="primary" class="uppercase" (click)="openFunderDialog()">add funder</button>
            </div>
            <div *ngIf="funders.controls?.length === 0">
                <mat-hint>Add funding to complete the author information.
                </mat-hint>
            </div>
            <div cdkDropList (cdkDropListDropped)="dropFunders($event)">
                <div class="bc-primary-default flex items-center justify-between p2 mat-elevation-z2 mb1"
                    *ngFor="let funder of funders.controls;let j=index" [formGroupName]="j" cdkDrag
                    [cdkDragDisabled]="(funders.controls!.length < 2)">
                    <div>
                        <p class="h4 m0">{{funder.value.institution.name}}</p>
                        <div class="flex items-center">
                            <div *ngIf="funder.value.institution.id">
                                <a
                                    href="{{funder.value.institution.type}}{{funder.value.institution.id}}"><small>{{funder.value.institution.id}}</small></a>
                            </div>
                            <div *ngIf="funder.value.countryCode" class="flex items-center">
                                <mat-icon [class]="(funder.value.institution.id) ? 'ml1' : ''" aria-hidden="false"
                                    aria-label="Country">place</mat-icon>
                                <small>{{funder.value.countryCode}}</small>
                            </div>
                            <div *ngIf="funder.value.awardIds" class="flex items-center">
                                <mat-icon
                                    [class]="(funder.value.institution.id || funder.value.countryCode ) ? 'ml1' : ''"
                                    aria-hidden="false" aria-label="Awards">military_tech</mat-icon>
                                <small>{{funder.value.awardIds}}</small>
                            </div>
                        </div>
                    </div>

                    <div class="flex items-center" *ngIf="author.editable">
                        <button mat-icon-button aria-label="More actions" matTooltip="Edit funder"
                            (click)="openFunderDialog(funder)">
                            <mat-icon>create</mat-icon>
                        </button>
                        <button mat-icon-button class="mr1" matTooltip="Remove funder" aria-label="Delete"
                            (click)="deleteFunder(funder)">
                            <mat-icon>delete</mat-icon>
                        </button>

                        <button mat-icon-button class="mr1 drag-handle" matTooltip="Re-order funder" aria-label="Drag"
                            cdkDragHandle *ngIf="funders.controls!.length > 1">
                            <mat-icon>drag_indicator</mat-icon>
                        </button>

                        <div class="drag-placeholder" *cdkDragPlaceholder></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="my3" *ngIf="isAuthor && type?.value !== 'group'" formArrayName="groups">
            <div class="flex items-center justify-between">
                <h3>Groups</h3>
                <button mat-button color="primary" class="uppercase" (click)="openGroupDialog()">add group</button>
            </div>
            <div *ngIf="groups$ | async; let groups">
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Groups</mat-label>
                    <mat-select [formControlName]="0">
                        <mat-option [value]="null">No group</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let group of groups" [value]="group.authorId">{{ group.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="groups.length === 0">
                    <mat-hint>Add groups multiple authors collaborated in.
                    </mat-hint>
                </div>
            </div>
        </div>

    </div>
    <div class="flex flex-end flex-wrap mx2 items-center" *ngIf="author.editable">
        <mat-checkbox color="primary" [ngStyle]="{'display': isAuthor && author.currentUser ? 'block' : 'none'}"
            [checked]="author.currentUser!" #updateProfileBottom>Update profile</mat-checkbox>
        <div class="px1">
            <button mat-button class="uppercase" (click)="close()">Discard Changes</button>
        </div>
        <button mat-button color="primary" class="uppercase"
            (click)="save({ updateProfile: updateProfileBottom?.checked })"
            [disabled]="authorForm.pristine && authorForm.valid">save</button>
    </div>
</form>