import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { gql } from '@apollo/client/core';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { selectActiveEditor } from 'projects/reference-app/src/app/app-state.reducer';
import { take } from 'rxjs/operators';
import { fileUploaded } from './shared.store';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private store: Store
  ) { }

  public static getFileIdFromName(documentName: string): string {
    return documentName.split('.').slice(0, documentName.split('.').length - 1).join('-').replace(' ', '-').toLowerCase();
  }

  /** Reads an image from the original upload (e.g. docx) and re-uploads it */
  async extractMedia(projectId: string, documentName: string, assets: { key: string; }[]): Promise<{ id: string; type: 'image', url: string; }[]> {
    const files: any[] = [];
    let fileNumber = 0;
    for (let asset of assets) {
      fileNumber++;
      // we get the file from the server and then upload it there.
      // this could include a preview component / cropping component and other checks (otherwise we could have had processed the files on the server directly)
      // we do not do this to keep the server side simple (even though this has massive performance / network implications).
      // Since this is the initial import, we accept that

      const fileUrl = `/import/asset/${projectId}/${documentName}/media/${asset.key.replace('media/', '')}`; // being explicit with media here
      const file = await fetch(fileUrl);
      // TODO extract asset.stats
      if (file.status === 200) {
        const blob = await file.blob();
        const fileName = FileService.getFileIdFromName(documentName) + '-' + asset.key.replace('media/', '');
        this.snackBar.open(`(${fileNumber}/${assets.length}) Processing ${fileName}`);
        const result = await this.uploadFile(projectId, new File([blob], fileName), `(${fileNumber}/${assets.length})`);
        console.log('asset upload result', { asset, result });
        // we keep the original id, since that's whats referenced in the document
        // TODO add absolute URL here
        files.push({ id: asset.key, type: 'image', name: fileName, url: `/export/asset/${projectId}/${fileName}` });
      } else {
        files.push({ id: asset.key, name: undefined, url: undefined });
        console.error('Could not process asset: ' + file.statusText, { asset, fileUrl });
        this.snackBar.open('Could not process ' + asset.key);
      }
    }
    return files;
  }

  /** Upload a file to the project */
  async uploadFile(projectId: string, file: File, message?: string) {

    const instance = await this.store.select(selectActiveEditor).pipe(take(1)).toPromise();
    if (!projectId && instance.projectId) { projectId = instance.projectId; }
    if (!projectId) { throw new Error('Project ID must be provided'); }

    console.log('Uploading file', projectId, file.name, file);
    const ref = this.snackBar.open(' 💾  Saving file to server ...');
    const uploadResult: any = await this.apollo.mutate({
      mutation: gql`mutation Upload($file: Upload!, $projectId: String) {
        uploadFile(input: { file: $file, projectId: $projectId }) {
          key
          projectId
          version {
            VersionId
            ETag
          }
        }
      }`,
      variables: { file, projectId }
    }).toPromise();

    ref.dismiss();

    const result = uploadResult?.data?.uploadFile;
    this.snackBar.open((message ? `${message} ` : '') + 'Saved file as ' + result?.key, undefined, { duration: 2000 });
    const ext = file.name.split('.')[file.name.split('.').length - 1];
    this.store.dispatch(fileUploaded({ projectId, name: file.name, extension: ext }));
    return result;
  }

  async getPreview(projectId, documentName) {
    console.log('Loading preview', { projectId, documentName });
    let documentResult: any;
    try {
      documentResult = await this.apollo.query({
        query: gql`query GetPreview($key: String!, $version: String) {
        document(key: $key, version: $version) {
          key
          manuscript(processCitations: true) {
            document
            authors
            metaData
            references
            lastModified
            template
            files {
              id
              type
              url
            }
          }
          preview(processCitations: true) {
            html
            references
            assets
            errors
            listings
          }
        }
      }`,
        variables: {
          key: `${projectId}/${documentName}`
        }
      }).toPromise();
    } catch (e) {
      return { key: `${projectId}/${documentName}`, errors: e.graphQLErrors };
    }

    const { key, preview } = documentResult.data?.document;

    return {
      key, preview, manuscript: documentResult.data?.document?.manuscript
    }
  }
}
