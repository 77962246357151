<div class="dca-heading flex items-center justify-between px2" *ngIf="data$ | async; let data; else loading">
  <div class="h2 flex items-center">
    <span>Export</span>
  </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>

<div class="dca-scrollarea">
  <div *ngIf="data$ | async; let data">
    <form>
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Template</mat-label>
        <mat-select name="template" (selectionChange)="navigateToTemplate($event.value)"
          [value]="data.template?.slug" [compareWith]="compareTemplate">
          <mat-option *ngFor="let template of data.templates" [value]="template">
            {{ template }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div *ngIf="activeTemplate$ | async; let template; else selectTemplate;" class="m1 p1">
      <b *ngIf="template.title">{{ template.title }}</b>
      <p *ngIf="template.description"><i>{{ template.description }}</i></p>
      <div>
        <p>The export options will use the manuscript and any author data that was provided to export into various
          formats.</p>
        <p>To learn more about how to create your own templates, please see the <a
            href="https://docs.sciflow.org/development/tdk/" target="_blank">Template Development Kit</a></p>
      </div>
      <div class="flex col-10 mx-auto p1 justify-between">
        <button mat-stroked-button [disabled]="!canEdit(data.editor.key)" class="mt1"
          (click)="exportWithTemplate(data.template?.slug, data.editor.key, 'pagedjs')" matTooltip="Paged.js - Print PDF in
      browser">PDF (paged.js)</button>
      <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="more formats">
        <mat-icon>expand_more</mat-icon>
      </button>
      <span>
          <button mat-icon-button *ngIf="data?.template?.metaData" matTooltip="Template meta data options"
            (click)="toggleMetaData()">
            <mat-icon>settings</mat-icon>
          </button>
          <button mat-icon-button (click)="resetMetaData()" matTooltip="Reset settings to template defaults">
            <mat-icon>restart_alt</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="data.template?.runners?.includes('xml')" [disabled]="!canEdit(data.editor.key)"
              (click)="openLens(data.template?.slug, data.editor.key, 'xml')"><b>Lens Viewer</b> (JATS XML)</button>
            <button mat-menu-item *ngIf="data.template?.runners?.includes('xml')" [disabled]="!canEdit(data.editor.key)"
              (click)="exportWithTemplate(data.template?.slug, data.editor.key, 'xml')"><b>XML</b> (JATS XML)</button>
            <button mat-menu-item *ngIf="data.template?.runners?.includes('html')" [disabled]="!canEdit(data.editor.key)"
              (click)="exportWithTemplate(data.template?.slug, data.editor.key, 'html')"><b>HTML</b></button>
            <button mat-menu-item *ngIf="data.template?.runners?.includes('princexml')" [disabled]="!canEdit(data.editor.key)" matTooltip="Export using PrinceXML"
              (click)="exportWithTemplate(data.template?.slug, data.editor.key, 'princexml')"><b>PrinceXML</b>
              (PDF)</button>
              <mat-divider></mat-divider>
              <button mat-menu-item [disabled]="!canEdit(data.editor.key)" matTooltip="Export Data Snapshot"
              (click)="exportSnapshot(data.editor.key)"><b>Data Snapshot</b> (ZIP)</button>
          </mat-menu>
        </span>
      </div>
    </div>
  </div>

  <ng-template #selectTemplate>
    <p class="m2">
      <b>Please select a template above.</b>
    </p>
  </ng-template>

  <div class="meta-data-editor">
    <div #metaData class="m1"></div>
  </div>
</div>

<ng-template #loading>
  <div class="dca-heading flex items-center justify-between px2">
    <div class="h2 flex items-center">
      <span>Loading ...</span>
    </div>
  </div>
</ng-template>