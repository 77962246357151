import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { selectSelectedAuthor, selectUnsavedData } from '../author-management.reducer';
import { markSaved, maximized, minimized, setSelectedAuthor, updateAuthor, updatePosition } from '../author-management.actions';
import { take } from 'rxjs/operators';
import { selectActiveEditor, selectAuthors } from 'projects/reference-app/src/app/app-state.reducer';
import { Router } from '@angular/router';

@Component({
  selector: 'sf-author-list',
  templateUrl: './author-list.component.html',
  styleUrls: ['./author-list.component.scss']
})
export class AuthorListComponent implements OnInit {
  authors$ = this.store.select(selectAuthors);
  selectedAuthorId$ = this.store.select(selectSelectedAuthor);

  constructor(private store: Store, private router: Router) { }

  async selectAuthor(authorId: string | null) {
    const unsaved = await this.store.select(selectUnsavedData).pipe(take(1)).toPromise();
    if (unsaved) {
      if (!confirm('Leave without saving?')) {
        return;
      }
    }

    this.store.dispatch(markSaved());
    const selectedAuthor = await this.selectedAuthorId$.pipe(take(1)).toPromise();
    if (!authorId && selectedAuthor === authorId) {
      this.store.dispatch(minimized());
      this.store.dispatch(setSelectedAuthor({ authorId: null }));
    } else {
      const documentInstances = await this.store.select(selectActiveEditor).pipe(take(1)).toPromise();
      this.router.navigate([`/write/${documentInstances.key}/${documentInstances.version}`, { outlets: { context: ['authors', 'author', authorId] } }], { replaceUrl: true });
      this.store.dispatch(maximized());
      this.store.dispatch(setSelectedAuthor({ authorId }));
    }

  }

  onClosedEdit() {
    this.selectAuthor(null);
  }

  drop(event: CdkDragDrop<string[]>) {
    this.store.dispatch(updatePosition({ currentIndex: event.currentIndex, previousIndex: event.previousIndex }));
  }

  onChange({ author, clearData, updateProfile }) {
    this.store.dispatch(updateAuthor({ author, clearData, updateProfile }));
  }

  ngOnInit(): void {
  }

}
