import { Injectable } from '@angular/core';
import { TeX } from 'mathjax-full/js/input/tex.js';
import { AllPackages } from 'mathjax-full/js/input/tex/AllPackages';
import { mathjax } from 'mathjax-full/js/mathjax';
import { SVG } from 'mathjax-full/js/output/svg';

import { chooseAdaptor } from 'mathjax-full/js/adaptors/chooseAdaptor.js';
import { STATE } from 'mathjax-full/js/core/MathItem.js';
import { RegisterHTMLHandler } from 'mathjax-full/js/handlers/html.js';

@Injectable({
    providedIn: 'root'
})
export class MathService {

    mathJaxDocument?: any;

    constructor() {
        const adaptor: any = chooseAdaptor();
        RegisterHTMLHandler(adaptor);

        this.mathJaxDocument = mathjax.document('<html></html>', {
            compileError(doc: any, math: any, err: any): any {
                console.log('compileError', doc, math, err);
                doc.compileError(math, err);
            },
            typesetError(doc: any, math: any, err: any): any {
                console.log('typesetError', doc, math, err);
                doc.typesetError(math, err);
            },
            InputJax: [
                new TeX({
                    packages: [...AllPackages, 'physics'],        // extensions to use
                    inlineMath: [              // start/end delimiter pairs for in-line math
                        ['\\(', '\\)']
                    ],
                    displayMath: [             // start/end delimiter pairs for display math
                        ['$$', '$$'],
                        ['\\[', '\\]']
                    ],
                    processEscapes: true,      // use \$ to produce a literal dollar sign
                    processEnvironments: true, // process \begin{xxx}...\end{xxx} outside math mode
                    processRefs: true,         // process \ref{...} outside of math mode
                    digits: /^(?:[0-9]+(?:\{,\}[0-9]{3})*(?:\.[0-9]*)?|\.[0-9]+)/,
                    // pattern for recognizing numbers
                    tags: 'none',              // or 'ams' or 'all'
                    tagSide: 'right',          // side for \tag macros
                    tagIndent: '0.8em',        // amount to indent tags
                    useLabelIds: true,         // use label name rather than tag for ids
                    multlineWidth: '85%',      // width of multline environment
                    maxMacros: 1000,           // maximum number of macro substitutions per expression
                    maxBuffer: 5 * 1024,       // maximum size for the internal TeX string (5K)
                    baseURL:                   // URL for use with links to tags (when there is a <base> tag in effect)
                        (document.getElementsByTagName('base').length === 0) ?
                            '' : String(document.location).replace(/#.*$/, ''),
                    formatError:               // function called when TeX syntax errors occur
                        (jax: any, err: any) => {
                            throw err;
                            // return jax.formatError(err);
                        }
                })],
            OutputJax: new SVG({
                scale: 1,                      // global scaling factor for all expressions
                minScale: .5,                  // smallest scaling factor to use
                mtextInheritFont: false,       // true to make mtext elements use surrounding font
                merrorInheritFont: true,       // true to make merror text use surrounding font
                mathmlSpacing: false,          // true for MathML spacing rules, false for TeX rules
                skipAttributes: {},            // RFDa and other attributes NOT to copy to the output
                exFactor: .5,                  // default size of ex in em units
                displayAlign: 'center',        // default for indentalign when set to 'auto'
                displayIndent: '0',            // default for indentshift when set to 'auto'
                fontCache: 'local',            // or 'global' or 'none'
                localID: null,                 // ID to use for local font cache (for single equation processing)
                internalSpeechTitles: true,    // insert <title> tags with speech content
                titleID: 0                     // initial id number to use for aria-labeledby titles
            })
        });
    }

    public renderEquation(content: string): { errors: any[], node?: any; } {
        try {
            if (localStorage.getItem('use-mathJax') !== 'true') {
                return this.renderMathJax(content);
            }
            return { errors: [], node: this.renderMathJax(content) };
        } catch (e: any) {
            console.error('Could not render equation', e.message || e);
            return { errors: [{ id: 'Unexpected', message: e.message }] };
        }
    }

    public renderMathJax(content: string): { node?: any; errors: any[] } {
        try {
            const node = this.mathJaxDocument.convert(content, { end: STATE.TYPESET });
            return { errors: [], node: node?.innerHTML };
        } catch (e) {
            return { errors: [e], node: null };
        }
    }
}
