import { SFNodeType, schemas } from './';
import { Node, DOMSerializer } from 'prosemirror-model';

export const parse = (json: any) => {
    return Node.fromJSON(schemas.manuscript, json);
};

export const toDOM = (doc: any, opts?: { document?: any }) => {
    const serializer = DOMSerializer.fromSchema(schemas.manuscript);
    const documentInstance =  opts?.document || document;
    if (!documentInstance) { throw new Error('Document must be provided for DOM access'); }
    return serializer.serializeNode(doc, { document: documentInstance });
};
