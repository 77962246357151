import { SFNodeType } from '@sciflow/schema';
import { Node } from 'prosemirror-model';
import { Plugin, PluginKey, EditorState } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Observable, Subject } from 'rxjs';

export const hoveringPluginKey = new PluginKey('hovering');

export interface HoverEvent {
    type: string;
    id: string;
    pos: number;
    coords: { top: number; left: number; };
    node: any;
    inFigure: boolean;
    parent?: any;
}

/** Tries to find matching node type at the position (or any parent) */
export const matchNode = (view, pos: number, types = [SFNodeType.heading, SFNodeType.paragraph, SFNodeType.figure, SFNodeType.blockquote]): HoverEvent | null => {
    const state = view.state;
    const resPos = state.doc.resolve(pos);
    
    const inFigure = resPos.node(1)?.type.name === SFNodeType.figure;

    // start at the current position and go up the document tree
    // until we reach a node we want to change (like a paragraph)
    let offset = resPos.parentOffset;  // TODO reset offset at lower levels
    for (let depth = resPos.depth; depth >= 0; depth--) {
        const node = resPos.node(depth);
        if (types.includes(node.type.name)) {
            const coords = view.coordsAtPos(pos - offset);
            return ({
                type: node.type.name,
                id: node.attrs?.id,
                pos: pos - offset,
                coords,
                node,
                parent,
                inFigure
            });
        }
    }

    return null;
};

/** Handles mouseovers on elements uses can interact with
 * Returns an event stream that can be subscribed to.
 */
export const createHoveringPlugin = (hoverNodeTypes?): { plugin: Plugin, events: Observable<HoverEvent> } => {
    const events = new Subject<HoverEvent>();
    const plugin = new Plugin({
        key: hoveringPluginKey,
        props: {
            handleDOMEvents: {
                // this will not work on touch displays (no mouseover)
                mouseover: (view, event: Event) => {
                    if (event instanceof MouseEvent) {
                        const state = view.state;
                        const posAtCoords = view.posAtCoords({ left: event.clientX, top: event.clientY });
                        if (!posAtCoords) { return false; }
                        // when on the border of a node we want to land inside it => + 1
                        const node = matchNode(view, posAtCoords?.pos, hoverNodeTypes);
                        if (node) {
                            events.next(node);
                        }
                    }
                    return false;
                }
            },
        }
    });

    return {
        plugin,
        events
    };
};
